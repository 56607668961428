import React, { useEffect } from "react";
import { shallow } from "zustand/shallow";
import ButtonMUI from "../../../components/Atoms/Button";
import InputMUI from "../../../components/Atoms/InputMUI/InputMUI";
import Loader from "../../../components/common/Loader";
import {
  InputDateTimeFormat,
  UTCFormat,
} from "../../../components/common/time";
import useToast from "../../../hooks/Toast/useToast";
import { useApi } from "../../../hooks/useApi";
import useForm from "../../../hooks/useForm";
import useSupervisorStore from "../supervisorStoe";

const HoureterChage = ({ handleClose, userId, ClosePopups }) => {
  const localUser = JSON.parse(localStorage.getItem("user"));
  const working_area_id = localUser?.working_area;
  const localUserId = userId ? userId : localUser._id;

  const { selectedVehicleData, setDataUpdate } = useSupervisorStore(
    (state) => ({
      selectedVehicleData: state.selectedVehicleData,
      setDataUpdate: state.setDataUpdate,
    }),
    shallow
  );

  const initialState = {
    opening_hr_meter: "",
    breakdown_start_time: selectedVehicleData?.closing_time,
    breakdown_end_time: selectedVehicleData?.closing_time,
  };

  const { post, patch, loading } = useApi();
  const { showToast } = useToast();
  const { values, handleChange } = useForm(initialState);

  useEffect(() => {
    handleClose();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let opening_payload = {
      hour_Meter_Change: true,
      user: localUserId,
      working_area: working_area_id,
      vehicle: selectedVehicleData?.vehicle?._id,
      slab_rate: selectedVehicleData?.vehicle?.default_slab,
      opening_hr_meter: values?.opening_hr_meter,
      opening_time: UTCFormat(values.breakdown_start_time),
    };

    let breakdown_payload = {
      hour_Meter_Change: true,
      breakdown_reason: "64131200622a4e829ff48997",
      breakdown_opening_hr_meter: values?.opening_hr_meter,
      breakdown_closing_hr_meter: values?.opening_hr_meter,
      breakdown_opening_time: UTCFormat(values.breakdown_start_time),
      breakdown_closing_time: UTCFormat(values.breakdown_end_time),
    };

    let closing_payload = {
      closing_hr_meter: values?.opening_hr_meter,
      closing_time: UTCFormat(values.breakdown_end_time),
    };

    try {
      let response;

      response = await post("supervisor-add-entry/create", opening_payload);

      if (!response?.error) {
        response = await patch(
          `supervisor-add-entry/breakdown-update/${selectedVehicleData?.vehicle?._id}`,
          breakdown_payload
        );

        if (!response?.error) {
          response = await patch(
            `supervisor-add-entry/update-closing/${selectedVehicleData?.vehicle?._id}`,
            closing_payload
          );

          if (!response?.error) {
            ClosePopups();
            setDataUpdate(event.timeStamp);
          }
        }
      }
    } catch (err) {
      showToast(err, "error");
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Loader open={loading} />
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-6">
          <InputMUI
            required
            min={0.1}
            value={values.opening_hr_meter}
            onChange={handleChange}
            onKeyPress={(e) => {
              if (!/[0-9 .]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            type="number"
            label="Opening hour meter"
            name="opening_hr_meter"
            placeholder="Opening hour meter"
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InputMUI
            required
            label="Breakdown start time for hour meter change"
            min={InputDateTimeFormat(selectedVehicleData?.closing_time)}
            value={InputDateTimeFormat(values.breakdown_start_time)}
            max={InputDateTimeFormat(new Date())}
            onChange={handleChange}
            type="datetime-local"
            step={60}
            name="breakdown_start_time"
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InputMUI
            required
            label="Breakdown end time for hour meter change"
            min={InputDateTimeFormat(values.breakdown_start_time)}
            value={InputDateTimeFormat(values.breakdown_end_time)}
            max={InputDateTimeFormat(new Date())}
            onChange={handleChange}
            type="datetime-local"
            step={60}
            name="breakdown_end_time"
          />
        </div>
        <ButtonMUI
          className="col-span-12"
          fullWidth
          type="submit"
          text="Save"
        />
      </div>
    </form>
  );
};

export default HoureterChage;
