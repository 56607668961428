import { createWithEqualityFn } from "zustand/traditional";

const ManagerDashboardStore = createWithEqualityFn((set) => ({
  managerDashboardData: [],
  error: "",
  loading: "",
  searchText: "",
  filteredData: [],
  status: "all",
  timeStamp: "",

  setManagerDashboardData: (data) =>
    set((state) => ({ managerDashboardData: data })),

  setError: (newError) => set((state) => ({ error: newError })),

  setLoading: (newLoading) => set((state) => ({ loading: newLoading })),

  setSearchText: (newSearchText) =>
    set((state) => ({ searchText: newSearchText })),

  setFilteredData: (newData) => set((state) => ({ filteredData: newData })),

  setStatus: (value) => set((state) => ({ status: value })),

  updateData: (value) => set((state) => ({ timeStamp: value })),

  resetData: () =>
    set(() => ({
      data: [],
      error: "",
      loading: "",
      searchText: "",
      filteredData: [],
    })),
}));

export default ManagerDashboardStore;
