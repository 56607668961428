import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";
import { Icon } from "../../assets/icon";
import DashboardStore from "../../pages/Dashboard/store/DashboardStore";
import useSupervisorStore from "../../pages/Supervisor/supervisorStoe";
import Store from "../../store/loggerStore";

const LeftSidebar = () => {
  const navigate = useNavigate();

  const { resetSupervisorStore } = useSupervisorStore(
    (state) => ({
      resetSupervisorStore: state.resetData,
    }),
    shallow
  );
  const { resetDashboardStore } = DashboardStore(
    (state) => ({
      resetDashboardStore: state.resetData,
    }),
    shallow
  );

  const { setUser } = Store(
    (state) => ({
      setUser: state.setUser,
    }),
    shallow
  );

  const [isOpen, setIsOpen] = useState(false);

  const Logoutfunc = () => {
    localStorage.clear();
    setUser(null);
    resetSupervisorStore();
    resetDashboardStore();
    navigate("/login");
  };

  return (
    <div className="hidden h-screen w-fit bg-neutral-300 p-2 pb-12 lg:flex flex-col justify-between items-center">
      <Tooltip title="home">
        <span onClick={() => navigate("/")} className="cursor-pointer">
          {Icon("oc_logo")}
        </span>
      </Tooltip>

      <Tooltip
        title="Logout"
        className="cursor-pointer mb-10"
        onClick={() => setIsOpen(true)}
      >
        {Icon("logout")}
      </Tooltip>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do You want to Logout!!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>No</Button>
          <Button onClick={Logoutfunc} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LeftSidebar;
