import { Delete, Edit, ExpandMore } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { shallow } from "zustand/shallow";
import ButtonMUI from "../../../components/Atoms/Button";
import Popup from "../../../components/MUI/Popup";
import Loader from "../../../components/common/Loader";
import {
  LocalTime,
  TimeDurationInDays,
  currentDate,
} from "../../../components/common/time";
import { useApi } from "../../../hooks/useApi";
import DashboardStore from "../store/DashboardStore";
import ExitMenu from "./ExitMenu";
import ExitTime from "./ExitTime";

const VehicleListTable = () => {
  const { vehicleListFilteredData, setSelectVehicleData, setTimeStamp } =
    DashboardStore(
      (state) => ({
        vehicleListFilteredData: state.vehicleListFilteredData,
        loading: state.loading,
        setSelectVehicleData: state.setSelectVehicleData,
        setTimeStamp: state.setTimeStamp,
      }),
      shallow
    );

  const { del, loading } = useApi();

  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectedRow, SetselectedRow] = useState();

  const [selectedId, setSelectedId] = useState(false);

  const handleExpandClick = (item) => {
    setExpanded(!expanded);
    SetselectedRow(item._id);
  };

  const handleClick = (event, row) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const onOpenModalFun = (e) => {
    setModalOpen(true);
    handleCloseMenu();
  };

  const handleDeleteVehicle = async () => {
    const response = await del(`vehicle/delete/${selectedId}`);

    if (!response?.error) {
      setSelectedId(false);
      setTimeStamp(new Date().valueOf());
    }
  };

  const columns = [
    {
      headerName: "Action",
      field: "actions",
      type: "actions",
      minWidth: 200,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => (
        <>
          <Link to={`/logistic/vehicleDetails/${e.row?.vehicle?._id}`}>
            <IconButton title="button">
              <VisibilityIcon />
            </IconButton>
          </Link>
          <Link to={`/logistic/editVehicle/${e.row?.vehicle?._id}`}>
            <IconButton title="button">
              <Edit />
            </IconButton>
          </Link>
          <IconButton
            title="button"
            onClick={() => setSelectedId(e.row?.vehicle?._id)}
          >
            <Delete />
          </IconButton>

          {e?.row?.vehicle?.status !== "permanent_exit" && (
            <ButtonMUI
              onClick={(event) => {
                handleClick(event);
                setSelectVehicleData(e.row);
              }}
              text={
                e?.row?.vehicle?.status !== "permanent_exit" ? (
                  <MoreVertIcon />
                ) : (
                  <MoreVertIcon className="more-whiteIcon" />
                )
              }
            />
          )}
        </>
      ),
    },
    {
      field: "reg_no",
      headerName: "Registration No",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => e.row.vehicle.reg_no,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) =>
        e.row.vehicle?.status === "active"
          ? "Active"
          : e.row.vehicle?.status === "temporary_exit"
          ? "Temporary exit"
          : e.row.vehicle?.status === "release_request"
          ? "Release request"
          : "Permanent exit",
    },
    {
      field: "vendor_name",
      headerName: "Vendor Name",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => e.row.vehicle?.vendor?.vendor_name,
    },
    {
      field: "diesel_chargable",
      headerName: "Diesel chargable",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => (e.row.vehicle.diesel_chargable ? "Yes" : "No"),
    },
    {
      field: "vehicle_type",
      headerName: "Vehicle Type",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => e.row.vehicle?.vehicle_type?.name,
    },
    {
      field: "entryDate",
      headerName: "Entry Date",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => LocalTime(e.row.vehicle?.entry_date),
    },
    {
      field: "vehicle_validity",
      headerName: "Validity remaining",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) =>
        TimeDurationInDays(currentDate, e.row.vehicle.vehicle_validity) +
        " days",
    },
    {
      field: "opening_hr_meter",
      headerName: "Opening hour meter",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => e.row.opening_hr_meter,
    },
    {
      field: "closing_hr_meter",
      headerName: "Closing hour meter",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => e.row.closing_hr_meter || "Not closed yet",
    },
    {
      field: "opening_km",
      headerName: "km meter",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => e.row.vehicle.opening_km,
    },
  ];

  const getRowClassName = (params) => {
    if (
      params?.vehicle.status === "active" &&
      TimeDurationInDays(currentDate, params?.vehicle.vehicle_validity) < 16
    ) {
      return "validity";
    } else if (params?.vehicle.status === "active") {
      return "active-row-styles";
    } else if (params?.vehicle.status === "release_request") {
      return "release_request-styles";
    } else if (params?.vehicle.status === "temporary_exit") {
      return "temporary-row-styles";
    } else if (params?.vehicle.status === "permanent_exit") {
      return "permanent-row-styles";
    }
  };
  return (
    <>
      <Loader open={loading} />
      <div className="hidden lg:block h-[70vh]">
        <DataGrid
          rows={
            vehicleListFilteredData?.length > 0 ? vehicleListFilteredData : []
          }
          columns={columns}
          getRowId={(row) => row._id}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowClassName={(e) => getRowClassName(e.row)}
        />
      </div>
      <div className="lg:hidden block h-[80vh]">
        {vehicleListFilteredData?.length > 0 &&
          vehicleListFilteredData?.map((item, i) => (
            <Card key={i} className={`${getRowClassName(item)} my-2`}>
              <CardHeader
                avatar={
                  <Avatar>
                    <LocalShippingIcon color="info" />
                  </Avatar>
                }
                action={
                  <>
                    <IconButton
                      title="button"
                      onClick={() => handleExpandClick(item)}
                    >
                      <ExpandMore
                        expand={Boolean(expanded).toString()}
                        aria-expanded={Boolean(expanded).toString()}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </IconButton>

                    {item?.vehicle?.status !== "permanent_exit" && (
                      <IconButton
                        title="button"
                        onClick={(event) => {
                          handleClick(event);
                          setSelectVehicleData(item);
                        }}
                      >
                        {item?.vehicle?.status !== "permanent_exit" ? (
                          <MoreVertIcon />
                        ) : (
                          <MoreVertIcon className="more-whiteIcon" />
                        )}
                      </IconButton>
                    )}
                  </>
                }
                title={item?.vehicle.reg_no}
                subheader={item?.vehicle?.vehicle_type?.name}
              />

              {item?._id === selectedRow && (
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">Vendor:</span>
                      {item?.vehicle?.vendor?.vendor_name}
                    </Typography>
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">Entry Date:</span>
                      {LocalTime(item.vehicle?.entry_date)}
                    </Typography>
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">
                        Validity remaining:
                      </span>
                      {TimeDurationInDays(
                        currentDate,
                        item.vehicle.vehicle_validity
                      ) + " days"}
                    </Typography>
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">
                        Opening hour meter:
                      </span>
                      {LocalTime(item.vehicle?.opening_hr_meter)}
                    </Typography>
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">km meter:</span>
                      {item.vehicle?.opening_km}
                    </Typography>
                  </CardContent>
                  <Link to={`/logistic/vehicleDetails/${item?.vehicle?._id}`}>
                    <IconButton title="button">
                      <VisibilityIcon />
                    </IconButton>
                  </Link>
                  <Link to={`/logistic/editVehicle/${item?.vehicle?._id}`}>
                    <IconButton title="button">
                      <Edit />
                    </IconButton>
                  </Link>
                  <IconButton
                    title="button"
                    onClick={() => setSelectedId(item?.vehicle?._id)}
                  >
                    <Delete />
                  </IconButton>
                </Collapse>
              )}
            </Card>
          ))}
      </div>

      <ExitMenu
        openMenu={openMenu}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        onOpenModalFun={onOpenModalFun}
      />

      <ExitTime modalOpen={modalOpen} setModalOpen={setModalOpen} />

      <Popup open={Boolean(selectedId)}>
        <h6 className="text-bold">Are you sure want to delete?</h6>

        <div className="flex gap-2 mt-4">
          <ButtonMUI
            fullWidth
            type="button"
            onClick={() => setSelectedId(false)}
            text="Cancel"
          />

          <ButtonMUI
            fullWidth
            type="button"
            onClick={handleDeleteVehicle}
            text="delete"
          />
        </div>
      </Popup>
    </>
  );
};

export default VehicleListTable;
