import { FormControl, InputLabel, Select } from "@mui/material";
import React from "react";

const SelectInputMUI = ({
  onChange,
  name,
  value,
  label,
  placeholder,
  required,
  children,
  readOnly,
  disabled,
  defaultValue,
  multiple,
}) => {
  return (
    <FormControl required={required} fullWidth size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        disabled={disabled}
        defaultValue={defaultValue}
        label={label}
        placeholder={placeholder}
        value={value}
        readOnly={readOnly}
        name={name}
        onChange={onChange}
        required={required}
        multiple={multiple}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default SelectInputMUI;
