import React, { useRef, useState } from "react";
import MoreOptionContainer from "./components/MoreOptionContainer";
import SearchSection from "./components/SearchSection";
import SupervisorPopups from "./components/SupervisorPopups";
import VehicleLIstTable from "./components/VehicleLIstTable";

const SupervisorDashboard = (props) => {
  const userId = JSON.parse(localStorage.getItem("user"))?._id;

  const [modalShow, setModalShow] = useState(false);
  const [showmodaltype, setShowModaltype] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasCloselog, sethascloselog] = useState(false);

  const target = useRef(null);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const OpenModalHandler = (type) => {
    setShowModaltype(type);
    setModalShow(true);
  };

  const onHide = () => {
    setModalShow(false);
  };

  return (
    <>
      <SearchSection />

      <VehicleLIstTable target={target} handleClick={handleClick} />

      <MoreOptionContainer
        OpenModalHandler={OpenModalHandler}
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
      />

      <SupervisorPopups
        handleClose={handleClose}
        show={modalShow}
        onHide={onHide}
        showmodaltype={showmodaltype}
        userId={userId}
        sethascloselog={sethascloselog}
        hasCloselog={hasCloselog}
      />
    </>
  );
};

export default SupervisorDashboard;
