import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";
import DashboardStore from "../store/DashboardStore";

const ExitMenu = ({ anchorEl, openMenu, handleCloseMenu, onOpenModalFun }) => {
  const navigate = useNavigate();

  const { selectVehicleData } = DashboardStore(
    (state) => ({
      selectVehicleData: state.selectVehicleData,
    }),
    shallow
  );

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleCloseMenu}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {selectVehicleData?.closing_hr_meter &&
      selectVehicleData?.closing_time ? (
        [
          selectVehicleData?.vehicle?.status === "active" && (
            <MenuItem
              key="exit"
              onClick={() => onOpenModalFun(selectVehicleData?.vehicle?._id)}
            >
              Exit
            </MenuItem>
          ),
          selectVehicleData?.vehicle?.status === "release_request" && (
            <MenuItem
              key="gate-pass"
              disabled={
                selectVehicleData?.vehicle?.release_stage !== "fuel_issued" &&
                !selectVehicleData?.vehicle?.diesel_chargable
              }
              onClick={() => onOpenModalFun(selectVehicleData?.vehicle?._id)}
            >
              Issue gate pass
            </MenuItem>
          ),
          selectVehicleData?.vehicle?.status === "temporary_exit" && (
            <MenuItem
              key="re-active"
              onClick={() =>
                navigate(
                  "/logistic/reActive/" + selectVehicleData?.vehicle?._id
                )
              }
            >
              Re-active
            </MenuItem>
          ),
        ]
      ) : (
        <div className="px-2">Please add closing time</div>
      )}
    </Menu>
  );
};

export default ExitMenu;
