import moment from "moment";
import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import ButtonMUI from "../../../components/Atoms/Button";
import InputMUI from "../../../components/Atoms/InputMUI/InputMUI";
import Loader from "../../../components/common/Loader";
import {
  Duration,
  InputDateTimeFormat,
  UTCFormat,
} from "../../../components/common/time";
import { useApi } from "../../../hooks/useApi";
import useSupervisorStore from "../supervisorStoe";

const CloseTimeComponent = ({ handleClose, ClosePopups }) => {
  const { selectedVehicleData, setDataUpdate } = useSupervisorStore(
    (state) => ({
      selectedVehicleData: state.selectedVehicleData,
      setDataUpdate: state.setDataUpdate,
    }),
    shallow
  );

  const { patch, loading } = useApi();

  const initialFormData = {
    opening_hour_meter:
      selectedVehicleData?.breakdown_closing_hr_meter ||
      selectedVehicleData?.opening_hr_meter,
    closing_hour_meter:
      selectedVehicleData?.breakdown_closing_hr_meter ||
      selectedVehicleData?.opening_hr_meter, // Set closing hour meter same as opening time, so that user doesnt' have to struggle.
    opening_time:
      selectedVehicleData?.breakdown_closing_time ||
      selectedVehicleData?.opening_time,
    closing_time:
      selectedVehicleData?.breakdown_closing_time ||
      selectedVehicleData?.opening_time, // Set closing time same as opening time, so that user doesnt' have to struggle.
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      formValues?.closing_hour_meter - formValues?.opening_hour_meter >
      Duration(formValues?.opening_time, formValues?.closing_time)
    ) {
      setError("Total working hours must be greather than Total Hour Meter");
    } else {
      let payload = {
        closing_hr_meter: parseFloat(formValues?.closing_hour_meter).toFixed(2),
        closing_time: UTCFormat(formValues.closing_time),
      };
      const response = await patch(
        `supervisor-add-entry/update-closing/${selectedVehicleData?.vehicle?._id}`,
        payload
      );

      if (!response?.error) {
        ClosePopups();
        setDataUpdate(new Date().valueOf());
      }
    }
  };

  useEffect(() => {
    handleClose();
  }, []);

  return (
    <>
      <Loader open={loading} />
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-4">
            <InputMUI
              label={"Opening Hour Meter"}
              type="number"
              placeholder="Opening hours meter"
              name="opening_hour_meter"
              value={formValues.opening_hour_meter}
              onChange={handleInputChange}
              disabled
            />
          </div>
          <div className=" col-span-12 lg:col-span-4">
            <InputMUI
              label="Close Hour Meter"
              type="number"
              placeholder="Closing hours meter"
              name="closing_hour_meter"
              value={formValues.closing_hour_meter}
              onChange={handleInputChange}
              required
              min={parseFloat(
                selectedVehicleData?.breakdown_closing_hr_meter ||
                  selectedVehicleData?.opening_hr_meter
              )}
            />
          </div>
          <div className=" col-span-12 lg:col-span-4">
            <InputMUI
              label={"Total Hour Meter"}
              type="number"
              placeholder="Total hrs read"
              name="totalHrmeter"
              value={
                formValues.closing_hour_meter - formValues.opening_hour_meter
              }
              onChange={handleInputChange}
              className="custom-fields hidden-arrows"
              disabled
            />
          </div>

          <div className=" col-span-12 lg:col-span-4">
            <InputMUI
              label={"Opening Time"}
              type="datetime-local"
              step={60}
              value={InputDateTimeFormat(formValues.opening_time)}
              disabled
            />
          </div>
          <div className=" col-span-12 lg:col-span-4">
            <InputMUI
              name={"closing_time"}
              label={"Closing time"}
              type="datetime-local"
              step={60}
              value={InputDateTimeFormat(formValues.closing_time)}
              onChange={handleInputChange}
              required
              min={InputDateTimeFormat(
                selectedVehicleData?.breakdown_closing_time ||
                  selectedVehicleData?.opening_time
              )}
              max={InputDateTimeFormat(moment())}
            />
          </div>
          <div className=" col-span-12 lg:col-span-4">
            <InputMUI
              type="number"
              label="Total working hours"
              value={Duration(
                formValues?.opening_time,
                formValues?.closing_time
              )}
              disabled
            />
          </div>
          <p className="has-error-para2">{error}</p>
        </div>
        <ButtonMUI fullWidth text="Save" type="submit" />
      </form>
    </>
  );
};

export default CloseTimeComponent;
