import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Icon } from "../assets/icon";
import Bottombar from "./Bottombar";
import LeftSidebar from "./LeftSidebar";
import SideMenu from "./SideMenu";
import Footer from "./Footer";

const drawerWidth = 300;

const CommonLayout = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const base64 = btoa(
    new Uint8Array(user?.profilePicture?.data?.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );
  const { window } = 768;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className="h-screen w-scrren flex flex-col justify-between overflow-hidden">
      <Box sx={{ display: "flex", overflowY: "hidden", height: "100vh" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: "orange",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              <span className="text-black  flex gap-2 items-center font-bold">
                {Icon("person")}
                {user?.userName}
              </span>
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <div className="flex gap-2 justify-center items-center">
              <h3 className="font-bold">Logbook</h3>
              <img
                src={`data:image/jpeg;base64,${base64}`}
                alt="logo"
                height="40px"
                width="100px"
              />
            </div>
            {SideMenu()}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            <div className="flex">
              <LeftSidebar />
              <div className="w-full">
                <div className="flex gap-2 justify-center items-center">
                  <h3 className="font-bold">Logbook</h3>
                  <img
                    src={`data:image/jpeg;base64,${base64}`}
                    alt="logo"
                    height="40px"
                    width="100px"
                  />
                </div>

                {SideMenu()}
              </div>
            </div>
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            overflow: "hidden",
            height: "100%",
          }}
        >
          <Toolbar />
          <div className="h-full w-full overflow-y-auto p-2">
            {children}
            <div className="py-12"></div>
          </div>

          <div className="sticky top-auto bottom-0 left-0">
            <Footer />
          </div>
        </Box>
      </Box>

      <Bottombar />
    </div>
  );
};

export default CommonLayout;
