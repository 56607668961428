import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React, { useEffect } from "react";
import { shallow } from "zustand/shallow";
import ButtonMUI from "../../../components/Atoms/Button";
import InputMUI from "../../../components/Atoms/InputMUI/InputMUI";
import Loader from "../../../components/common/Loader";
import { InputDateTimeFormat, fromDate } from "../../../components/common/time";
import { useApi } from "../../../hooks/useApi";
import useForm from "../../../hooks/useForm";
import ManagerDashboardStore from "../store/ManagerStore";

const VehicleListSearchSection = () => {
  const { setFilteredData, timeStamp } = ManagerDashboardStore(
    (state) => ({
      setFilteredData: state.setFilteredData,
      timeStamp: state.timeStamp,
    }),
    shallow
  );

  const { get, loading, data } = useApi();
  const { values, handleChange } = useForm({
    startDate: fromDate(30),
    endDate: new Date(),
    searchText: "",
    status: "all",
  });

  const getFuelRequestLogs = async (e) => {
    const payload = {
      fromDate: values?.startDate,
      toDate: values?.endDate,
    };

    const Dateparams = new URLSearchParams(payload).toString();

    // NOTE: This list must be the same as store dashboard entry/exit/chargeable logs.
    // Hence the call is the same as store dashboard entry/exit/chargeable logs

    await get(
      `store-manager-dashboard/list?${Dateparams}&diesel_chargeable=true`
    );
  };

  useEffect(() => {
    getFuelRequestLogs();
  }, [timeStamp]);

  useEffect(() => {
    const filtered = data
      ?.filter((vehicle) =>
        !values?.status || values?.status === "all"
          ? true
          : values?.status.includes(vehicle?.approved_by_store_manager)
      )
      ?.filter((vehicle) => {
        return [
          vehicle?.reg_no,
          vehicle?.vendorName,
          vehicle?.workingArea,
          vehicle?.vehicleType,
          vehicle?.opening_km?.toFixed(0),
          vehicle?.entryDate,
          vehicle?.driverName,
          vehicle?.approved_by_store_manager,
          vehicle?.opening_hour_meter?.toFixed(0),
        ].some(
          (s) =>
            s && s?.toLowerCase().includes(values?.searchText?.toLowerCase())
        );
      });

    setFilteredData(filtered);
  }, [
    data?.[0]?.updatedAt,
    data?.[0]?.vehicle?.updatedAt,
    data?.length,
    values?.searchText,
    values?.status,
  ]);

  return (
    <>
      <Loader open={loading} />

      <div className="flex flex-col lg:flex-row gap-2 items-center">
        <InputMUI
          value={InputDateTimeFormat(values?.startDate)}
          onChange={handleChange}
          type="datetime-local"
          step={60}
          name="startDate"
          label="Start date"
        />

        <InputMUI
          value={InputDateTimeFormat(values?.endDate)}
          onChange={handleChange}
          type="datetime-local"
          step={60}
          name="endDate"
          label="End date"
        />

        <ButtonMUI onClick={getFuelRequestLogs} fullWidth text="Submit" />
      </div>

      <div className=" grid grid-cols-12 items-center gap-2 py-3">
        <div className="col-span-12 lg:col-span-5">
          <InputMUI
            value={values?.searchText}
            onChange={handleChange}
            type="text"
            name="searchText"
            placeholder="Search parameter here"
            label="Search parameter here"
          />
        </div>

        <div className="col-span-12 lg:col-span-7">
          <FormControl>
            <RadioGroup
              row
              name="status"
              value={values?.status}
              onChange={handleChange}
            >
              <FormControlLabel
                value="all"
                control={<Radio color="success" />}
                label="All"
              />
              <FormControlLabel
                value="approved"
                control={<Radio color="success" />}
                label="Approved"
              />
              <FormControlLabel
                value="pending"
                control={<Radio color="error" />}
                label="Pending"
              />
              <FormControlLabel
                value="rejected"
                control={<Radio color="warning" />}
                label="Rejected"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </>
  );
};

export default VehicleListSearchSection;
