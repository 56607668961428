export const GenerateRefNumber = async (
  plantId,
  type,
  deptHeader,
  get,
  put
) => {
  let response;

  response = await get(
    `reqserialnumber?type=${type}&deptHeader=${deptHeader}&plantId=${plantId}`
  );

  if (response?.error) {
    response = await put(
      `reqserialnumber?type=${type}&deptHeader=${deptHeader}&plantId=${plantId}`
    );
  }

  return response?.serialNumber;
};
