import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const menu = [
  // {
  //   name: "Logistic info",
  //   link: "/dashboard",
  // },
  {
    name: "Admin dashboard",
    link: "/admin/dashboard",
    logistic: false,
    admin: true,
  },
  {
    name: "Vehicle master",
    link: "/logistic/vehicle-list",
    logistic: true,
    admin: true,
  },
  {
    name: "Vendor master",
    link: "/logistic/vendor-list",
    logistic: true,
    admin: true,
  },
  {
    name: "Vehicle type master",
    link: "/logistic/vehicle-types",
    logistic: true,
    admin: true,
  },
  {
    name: "Change password",
    link: "/change_password",
    logistic: true,
    admin: false,
  },
];

const LogisticMenu = () => {
  const pathname = useLocation();

  const user = JSON.parse(localStorage.getItem("user"))?.user_type?.type;

  return (
    <div className="w-full">
      <List>
        {menu.map(
          (text, index) =>
            (user === "Logistic" ? text.logistic : text.admin) && (
              <Link key={index} to={text.link}>
                <ListItem
                  sx={{
                    backgroundColor:
                      pathname.pathname === text.link ? "orange" : "",
                  }}
                  key={index}
                  disablePadding
                >
                  <ListItemButton>
                    <div className="flex gap-1 items-center">
                      {/* <LocalShippingIcon /> */}
                      <ListItemText primary={text.name} />
                    </div>
                  </ListItemButton>
                </ListItem>
              </Link>
            )
        )}
      </List>
    </div>
  );
};

export default LogisticMenu;
