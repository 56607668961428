import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import ButtonMUI from "../../../components/Atoms/Button";
import InputMUI from "../../../components/Atoms/InputMUI/InputMUI";
import Loader from "../../../components/common/Loader";
import { GenerateRefNumber } from "../../../components/common/serialNumberGeneration";
import {
  Duration,
  InputDateTimeFormat,
  UTCFormat,
} from "../../../components/common/time";
import { useApi } from "../../../hooks/useApi";
import useSupervisorStore from "../supervisorStoe";

const ReIssueFuel = ({ handleClose, ClosePopups, userId }) => {
  const working_area_id = JSON.parse(
    localStorage.getItem("user")
  )?.working_area;

  const { selectedVehicleData, setDataUpdate } = useSupervisorStore(
    (state) => ({
      selectedVehicleData: state.selectedVehicleData,
      setDataUpdate: state.setDataUpdate,
    }),
    shallow
  );

  const { get, post, put, patch, loading } = useApi();

  const initialFormData = {
    closingHours:
      selectedVehicleData?.breakdown_closing_hr_meter ||
      selectedVehicleData?.opening_hr_meter, // Set closing hour meter same as opening time, so that user doesnt' have to struggle.
    opening_time: selectedVehicleData?.opening_time,
    closing_time: selectedVehicleData?.opening_time, // Set closing time same as opening time, so that user doesnt' have to struggle.
  };

  const getInitialClosingTime = function () {
    var compareTo = {};
    if (selectedVehicleData && selectedVehicleData.opening_time) {
      compareTo.Time = moment(selectedVehicleData.opening_time);
      compareTo.Name = "opening time";
    }

    if (
      compareTo.Time &&
      selectedVehicleData &&
      selectedVehicleData.breakdown_closing_time
    ) {
      var vehicleLogBreakdownCloseTime = moment(
        selectedVehicleData?.breakdown_closing_time
      );
      if (vehicleLogBreakdownCloseTime.isAfter(compareTo.Time)) {
        compareTo.Time = vehicleLogBreakdownCloseTime;
        compareTo.Name = "vehicle breakdown close time";
      }
    }

    if (
      compareTo.Time &&
      selectedVehicleData &&
      selectedVehicleData.fuel_issue_time
    ) {
      var lastFuelIssueTime = moment(selectedVehicleData.fuel_issue_time);
      if (lastFuelIssueTime.isAfter(compareTo.Time)) {
        compareTo.Time = lastFuelIssueTime;
        compareTo.Name = "last fuel issue time";
      }
    }
    return compareTo;
  };

  const [fuelIssueTimeErrorMsg, setFuelIssueTimeErrorMsg] = useState("");
  const [fuelVolume, setFuelVolume] = useState("");
  const [showLitersInput, setShowLitersInput] = useState(false);
  const [litFuel, setLitFuel] = useState(0);
  const [closeHourErrorMessage, setCloseHourErrorMessage] = useState("");
  const [closeTime, setCloseTime] = useState(getInitialClosingTime().Time);
  const [fuelIssueTime, setFuelIssueTime] = useState(closeTime);
  const [closeTimeErrMessage, setCloseTimeErrMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const handlefuelVolumeChange = (event) => {
    if (event.target.value === "liter") {
      setShowLitersInput(true);
    } else {
      setShowLitersInput(false);
      setLitFuel(event.target.value);
    }
    setFuelVolume(event.target.value);
  };

  useEffect(() => {
    handleClose();
  }, []);

  const fuelIssueTimeHandler = (e) => {
    let date = e.target.value;

    const inputDate = moment(date);

    if (inputDate.isBefore(moment(closeTime))) {
      setFuelIssueTimeErrorMsg(
        `Fuel issue date/time must be after closing time: ${moment(
          closeTime
        ).format("DD-MM-YYYY HH:mm:ss a")}`
      );
    } else {
      setFuelIssueTimeErrorMsg("");
    }
    setFuelIssueTime(date);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      parseFloat(formData?.closingHours) -
        parseFloat(
          selectedVehicleData?.breakdown_closing_hr_meter ||
            selectedVehicleData?.opening_hr_meter
        ) >
      Duration(
        selectedVehicleData?.breakdown_closing_time ||
          selectedVehicleData?.opening_time,
        closeTime
      )
    ) {
      setCloseTimeErrMessage(
        "Total time should be greater than Total Hour Meter"
      );
    } else {
      setCloseTimeErrMessage("");
      const data = new FormData(event.currentTarget);

      let payload = {
        closing_hr_meter: parseFloat(formData?.closingHours).toFixed(2),
        closing_time: UTCFormat(closeTime),
        temp_driver: data.get("temp_driver"),
      };

      let payload2 = {
        user: userId,
        working_area: working_area_id,
        vehicle: selectedVehicleData?.vehicle?._id,
        slab_rate: selectedVehicleData?.vehicle?.default_slab,
        opening_hr_meter: parseFloat(formData?.closingHours).toFixed(2),
        opening_time: UTCFormat(closeTime),
      };

      let payload3 = {
        fuel_issue_time: UTCFormat(fuelIssueTime),
        fuel_vol: litFuel,
        approved_by_store_manager: "pending",
      };

      const serialNumber = await GenerateRefNumber(
        selectedVehicleData?.vehicle?.plant_name?._id,
        "FuelIssue",
        "HSD",
        get,
        put
      );

      payload3.fuel_request_number = serialNumber;

      if (
        closeHourErrorMessage === "" &&
        closeTimeErrMessage === "" &&
        fuelIssueTimeErrorMsg === "" &&
        !isLoading
      ) {
        try {
          let response;
          response = await patch(
            `supervisor-add-entry/update-closing/${selectedVehicleData?.vehicle?._id}`,
            payload
          );
          if (!response?.error) {
            response = await post("supervisor-add-entry/create", payload2);
            if (!response?.error) {
              response = await patch(
                `supervisor-add-entry/update/${selectedVehicleData?.vehicle?._id}`,
                payload3
              );
              if (!response?.error) {
                ClosePopups();
                setDataUpdate(event.timeStamp);
              }
            }
          }
        } catch (err) {
          console.warn(err);
        }
      }
    }
  };

  const closeDateChange = (e) => {
    let date = e.target.value;
    const inputDate = moment(date);

    var compareTo = getInitialClosingTime();

    if (compareTo.Time) {
      if (inputDate.isBefore(compareTo.Time)) {
        setCloseTimeErrMessage(
          `Close date/time must be after ${
            compareTo.Name
          }: ${compareTo.Time.format("DD-MM-YYYY HH:mm:ss a")}`
        );
      } else {
        setCloseTimeErrMessage("");
      }
      setCloseTime(date);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "closingHours") {
      if (selectedVehicleData?.breakdown_closing_hr_meter) {
        if (
          parseFloat(value) <
          parseFloat(selectedVehicleData?.breakdown_closing_hr_meter)
        ) {
          setCloseHourErrorMessage(
            `Opening hours should be greater than or equal to ${selectedVehicleData?.breakdown_closing_hr_meter} (because breakdown hour meter was ${selectedVehicleData?.breakdown_closing_hr_meter}).`
          );
        } else {
          setCloseHourErrorMessage("");
        }
      } else if (selectedVehicleData?.opening_hr_meter) {
        if (
          parseFloat(value) < parseFloat(selectedVehicleData?.opening_hr_meter)
        ) {
          setCloseHourErrorMessage(
            `Opening hours should  greater than or equal to ${selectedVehicleData?.opening_hr_meter}.`
          );
        } else {
          setCloseHourErrorMessage("");
        }
      } else {
        // nothing
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Loader open={loading} />
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-6">
          <InputMUI
            required
            type="text"
            name="temp_driver"
            label="Driver"
            placeholder="Driver"
          />
        </div>

        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            label={"Opening hour meter"}
            value={selectedVehicleData?.opening_hr_meter}
            disabled
          />
        </div>
        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            label={"Opening Time"}
            type="datetime-local"
            step={60}
            value={InputDateTimeFormat(selectedVehicleData?.opening_time)}
            disabled
          />
        </div>

        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            type="number"
            label="Closing hours meter"
            name="closingHours"
            value={formData.closingHours}
            onChange={handleInputChange}
            required
            min={
              selectedVehicleData?.breakdown_closing_hr_meter ||
              selectedVehicleData?.opening_hr_meter
            }
          />
          <span className="text-red-700">{closeHourErrorMessage}</span>
        </div>
        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            value={InputDateTimeFormat(closeTime)}
            onChange={closeDateChange}
            label="Closing time"
            type="datetime-local"
            step={60}
            required
          />
          <span className="text-red-700">{closeTimeErrMessage}</span>
        </div>

        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            value={InputDateTimeFormat(fuelIssueTime)}
            onChange={fuelIssueTimeHandler}
            label="Fuel Issue Time"
            type="datetime-local"
            step={60}
            required
            min={InputDateTimeFormat(closeTime)}
            max={InputDateTimeFormat(moment())}
          />
        </div>
        <div className="col-span-12 lg:col-span-4">
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Fuel issue
            </FormLabel>
            <RadioGroup
              onChange={handlefuelVolumeChange}
              name="radioGroup"
              value={fuelVolume}
              row
            >
              <FormControlLabel
                control={<Radio />}
                value="full tank"
                label="full tank"
              />
              <FormControlLabel
                control={<Radio />}
                label="Liter"
                value="liter"
              />
            </RadioGroup>
          </FormControl>

          {showLitersInput && (
            <div className="mt-2">
              <div xs={12}>
                <InputMUI
                  required
                  min={1}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  value={litFuel}
                  label={"Liters"}
                  type="number"
                  onChange={(e) => setLitFuel(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <span className="text-red-700">{fuelIssueTimeErrorMsg}</span>
      </div>
      <ButtonMUI fullWidth text="Save" type="submit" />
    </form>
  );
};

export default ReIssueFuel;
