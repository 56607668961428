import { createWithEqualityFn } from "zustand/traditional";

const useSupervisorStore = createWithEqualityFn((set) => ({
  formValues: {},
  data: [],
  error: "",
  loading: "",
  searchText: "",
  filteredData: [],
  selectVehicleId: "",
  selectedVehicleData: "",
  dataChange: "",

  setFormValues: (formData) => set((state) => ({ formValues: formData })),

  updateData: (newData) => set((state) => ({ data: newData })),

  setError: (newError) => set((state) => ({ error: newError })),

  setLoading: (newLoading) => set((state) => ({ loading: newLoading })),

  setSearchText: (newSearchText) =>
    set((state) => ({ searchText: newSearchText })),

  setFilteredData: (newData) => set((state) => ({ filteredData: newData })),

  setSelectVehicleId: (id) => set((state) => ({ selectVehicleId: id })),

  SetselectedVehicleData: (data) =>
    set((state) => ({ selectedVehicleData: data })),

  setDataUpdate: (change) => set((state) => ({ dataChange: change })),

  resetData: () =>
    set((state) => ({
      data: [],
      filteredData: [],
      selectedVehicleData: "",
    })),
}));

export default useSupervisorStore;
