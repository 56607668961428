import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const menu = [
  {
    name: "Admin dashboard",
    link: "/admin/dashboard",
  },
  {
    name: "Plant",
    link: "/admin/plant",
  },
  {
    name: "User",
    link: "/admin/user",
  },
  {
    name: "Driver",
    link: "/admin/driver",
  },
  {
    name: "Add shift",
    link: "/admin/add_shift",
  },
  {
    name: "Add working area",
    link: "/admin/add_working_area",
  },
  {
    name: "Add break down reason",
    link: "/admin/add_break_down_reason",
  },
  {
    name: "Change password",
    link: "/change_password",
  },
];

const AdminMenu = () => {
  const pathname = useLocation();
  return (
    <List>
      {menu.map((text, index) => (
        <Link to={text.link} key={index}>
          <ListItem
            sx={{
              backgroundColor: pathname.pathname.includes(text.link)
                ? "orange"
                : "",
            }}
            key={index}
            disablePadding
          >
            <ListItemButton>
              <div className="flex gap-1 items-center">
                {/* <LocalShippingIcon /> */}
                <ListItemText primary={text.name} />
              </div>
            </ListItemButton>
          </ListItem>
        </Link>
      ))}
    </List>
  );
};

export default AdminMenu;
