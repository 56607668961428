import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import ButtonMUI from "../../../components/Atoms/Button";
import InputMUI from "../../../components/Atoms/InputMUI/InputMUI";
import Loader from "../../../components/common/Loader";
import {
  InputDateTimeFormat,
  UTCFormat,
} from "../../../components/common/time";
import { useApi } from "../../../hooks/useApi";
import useSupervisorStore from "../supervisorStoe";
import { GenerateRefNumber } from "../../../components/common/serialNumberGeneration";

const FuelIssueComponent = ({ handleClose, ClosePopups }) => {
  const { selectedVehicleData, setDataUpdate } = useSupervisorStore(
    (state) => ({
      selectedVehicleData: state.selectedVehicleData,
      setDataUpdate: state.setDataUpdate,
    }),
    shallow
  );

  const { get, put, patch, loading } = useApi();

  const [fuelIssueTime, setFuelissueTime] = useState(
    selectedVehicleData.opening_time
  );
  const [fuelVolume, setFuelVolume] = useState("");
  const [showLitInput, setShowLitInput] = useState(false);
  const [litFule, setLitFuel] = useState(0);
  const [error, setError] = useState("");

  const handlefuelVolumeChange = (event) => {
    if (event.target.value === "liter") {
      setShowLitInput(true);
    } else {
      setShowLitInput(false);
      setLitFuel(event.target.value);
    }
    setFuelVolume(event.target.value);
  };

  const fuelIssueTimeHandler = (e) => {
    let date = e.target.value;
    const fuelIssueDate = moment(date);
    let currentLogOpeningDate = moment(selectedVehicleData?.opening_time);
    if (fuelIssueDate.isBefore(currentLogOpeningDate)) {
      setError(
        `Fuel issue date/time must be greater than ${currentLogOpeningDate.format(
          "DD-MM-YYYY hh:mm a"
        )}`
      );
    } else {
      setError("");
    }
    setFuelissueTime(date);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let payload = {
      fuel_issue_time: UTCFormat(fuelIssueTime),
      fuel_vol: litFule,
      approved_by_store_manager: "pending",
      temp_driver: data.get("temp_driver"),
    };

    const serialNumber = await GenerateRefNumber(
      selectedVehicleData?.vehicle?.plant_name?._id,
      "FuelIssue",
      "HSD",
      get,
      put
    );

    payload.fuel_request_number = serialNumber;

    const response = await patch(
      `supervisor-add-entry/update/${selectedVehicleData?.vehicle?._id}`,
      payload
    );

    if (!response?.error) {
      ClosePopups();
      setDataUpdate(event.timeStamp);
    }
  };

  useEffect(() => {
    handleClose();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Loader open={loading} />
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-6">
          <InputMUI
            required
            type="text"
            name="temp_driver"
            label="Driver"
            placeholder="Driver"
          />
        </div>

        <div className="col-span-12 lg:col-span-6">
          <InputMUI
            placeholder="Time of fuel issue"
            label="Time of fuel issue"
            value={InputDateTimeFormat(fuelIssueTime)}
            onChange={fuelIssueTimeHandler}
            type="datetime-local"
            step={60}
            required
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Fuel issue
            </FormLabel>
            <RadioGroup
              onChange={handlefuelVolumeChange}
              name="radioGroup"
              value={fuelVolume}
              row
              aria-labelledby="demo-radio-buttons-group-label"
            >
              <FormControlLabel
                control={<Radio />}
                value="full tank"
                label="full tank"
              />
              <FormControlLabel
                control={<Radio />}
                label="Liter"
                value="liter"
              />
            </RadioGroup>
          </FormControl>

          {showLitInput && (
            <div className="col-span-12 lg:col-span-6">
              <InputMUI
                required
                type="number"
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => setLitFuel(e.target.value)}
                placeholder="Enter Fuel liter"
                min={1}
              />
            </div>
          )}
        </div>
        <p className="has-error-para">{error}</p>
      </div>
      <ButtonMUI fullWidth text="Save" type="submit" />
    </form>
  );
};

export default FuelIssueComponent;
