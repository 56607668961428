import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const menu = [
  {
    name: "Manager dashboard",
    link: "/manager",
  },
  {
    name: "Disel Price",
    link: "/disel-price",
  },
  {
    name: "Change password",
    link: "/change_password",
  },
];

const StoreMenu = () => {
  const pathname = useLocation();
  return (
    <List>
      {menu.map((text, index) => (
        <Link key={index} to={text.link}>
          <ListItem
            sx={{
              backgroundColor: pathname.pathname === text.link ? "orange" : "",
            }}
            key={index}
            disablePadding
          >
            <ListItemButton>
              <div className="flex gap-1 items-center">
                {/* <LocalShippingIcon /> */}
                <ListItemText primary={text.name} />
              </div>
            </ListItemButton>
          </ListItem>
        </Link>
      ))}
    </List>
  );
};

export default StoreMenu;
