import { createWithEqualityFn } from "zustand/traditional";

const Store = createWithEqualityFn((set) => ({
  user:
    localStorage.getItem("user") && localStorage.getItem("user") !== "undefined"
      ? JSON.parse(localStorage.getItem("user"))
      : null,
  timeStamp: "",

  setTimeStamp: (timeStamp) => set((state) => ({ timeStamp: timeStamp })),

  setUser: (user) => set((state) => ({ user: user })),

  resetData: () =>
    set(() => ({
      user: "",
    })),
}));

export default Store;
