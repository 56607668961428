import CloseIcon from "@mui/icons-material/Close";
import { capitalize } from "@mui/material";
import React from "react";
import { Navigate } from "react-router-dom";
import { shallow } from "zustand/shallow";
import Popup from "../../../../components/MUI/Popup";
import AnotherBreakDown from "../../PopupsComponents/AnotherBreakDown";
import BreakTimeComponent from "../../PopupsComponents/BreakTimeComponent";
import ChangeWorkingAreaComponent from "../../PopupsComponents/ChangeWorkingAreaComponent";
import CloseTimeComponent from "../../PopupsComponents/CloseTimeComponent";
import FuelIssueComponent from "../../PopupsComponents/FuelIssueComponent";
import HoureterChage from "../../PopupsComponents/HoureterChage";
import OpenTimeComponent from "../../PopupsComponents/OpenTimeComponent";
import ReIssueFuel from "../../PopupsComponents/ReIssueFuel";
import ReleaseVehicleForRepairing from "../../PopupsComponents/ReleaseVehicleForRepairing";
import SlabRateChange from "../../PopupsComponents/SlabRateChange";
import useSupervisorStore from "../../supervisorStoe";

const SupervisorPopups = (props) => {
  const { selectedVehicleData } = useSupervisorStore(
    (state) => ({
      selectedVehicleData: state.selectedVehicleData,
    }),
    shallow
  );

  const RenderModalFunc = (componentName) => {
    switch (componentName) {
      case "open-time":
        return (
          <OpenTimeComponent
            userId={props?.userId}
            ClosePopups={props.onHide}
            handleClose={props.handleClose}
          />
        );

      case "hour_meter_change":
        return (
          <HoureterChage
            handleClose={props.handleClose}
            ClosePopups={props.onHide}
          />
        );

      case "close-time":
        return (
          <CloseTimeComponent
            handleClose={props.handleClose}
            ClosePopups={props.onHide}
          />
        );

      case "break-time":
        return (
          <BreakTimeComponent
            handleClose={props.handleClose}
            ClosePopups={props.onHide}
          />
        );

      case "fule-issue":
        return (
          <FuelIssueComponent
            handleClose={props.handleClose}
            ClosePopups={props.onHide}
          />
        );

      case "working-area":
        return (
          <ChangeWorkingAreaComponent
            handleClose={props.handleClose}
            ClosePopups={props.onHide}
          />
        );

      case "vehicle-log":
        return Navigate("/vehicle-logs");

      case "ReIssue-Fuel":
        return (
          <ReIssueFuel
            handleClose={props.handleClose}
            ClosePopups={props.onHide}
            userId={props?.userId}
          />
        );

      case "slab_rate":
        return (
          <SlabRateChange
            handleClose={props.handleClose}
            ClosePopups={props.onHide}
            userId={props?.userId}
          />
        );

      case "release_vehicle_for_repairing":
        return (
          <ReleaseVehicleForRepairing
            handleClose={props.handleClose}
            ClosePopups={props.onHide}
            userId={props?.userId}
          />
        );

      case "AgianBreakdown":
        return (
          <AnotherBreakDown
            handleClose={props.handleClose}
            ClosePopups={props.onHide}
            userId={props?.userId}
            sethascloselog={props?.sethascloselog}
            hasCloselog={props?.hasCloselog}
          />
        );

      default:
      // Logic for handling unrecognized component names
    }
  };

  const RenderModalHeader = (componentName) => {
    switch (componentName) {
      case "open-time":
        return "Add Opening Time";

      case "hour_meter_change":
        return "Hour meter Change";

      case "close-time":
        return "Add Closing Time";

      case "break-time":
        return "Add Break Down";

      case "fule-issue":
        return "Add Fuel Issue";

      case "working-area":
        return "Change Working Area";

      case "vehicle-log":
        return "Vehicle log";

      case "ReIssue-Fuel":
        return "Re-Issue Fuel";

      case "AgianBreakdown":
        return "Another Break Down";

      case "slab_rate":
        return "Change working time";

      case "release_vehicle_for_repairing":
        return "Release Vehicle for repairing";

      default:
        return "";
    }
  };

  return (
    <Popup width={600} open={props.show} onClose={props.onHide}>
      <div className="flex justify-between items-center font-bold">
        <p className="d-flex align-items-center gap-2">
          {RenderModalHeader(props.showmodaltype)}
          <span className="text-orange-500 pl-2">
            {selectedVehicleData?.vehicle?.reg_no}
          </span>
        </p>
        <span className=" cursor-pointer" onClick={props.onHide}>
          <CloseIcon />
        </span>
      </div>

      <div className="flex flex-wrap gap-2 pb-4 justify-between items-center">
        <p>
          <span className="text-orange-500 pr-2"> Vendor:</span>
          {selectedVehicleData?.vehicle?.vendor?.vendor_name}
        </p>

        <p>
          <span className="text-orange-500 pr-2"> Diesel Chargable:</span>
          {selectedVehicleData?.vehicle?.diesel_chargable ? "Yes" : "No"}
        </p>

        <p>
          <span className="text-orange-500 pr-2"> Working time:</span>
          {
            selectedVehicleData?.vehicle?.slab?.filter(
              (slab) =>
                capitalize(slab.slab_type ? slab.slab_type : "") ===
                capitalize(
                  selectedVehicleData?.vehicle?.default_slab
                    ? selectedVehicleData?.vehicle?.default_slab
                    : "NA"
                )
            )?.[0]?.no_of_hours
          }
        </p>
      </div>

      {RenderModalFunc(props.showmodaltype)}
    </Popup>
  );
};

export default SupervisorPopups;
