import { Button } from "@mui/material";
import React from "react";

const ButtonMUI = ({
  type = "button",
  text = "login",
  fullWidth,
  disabled,
  className,
  onClick,
  title = "button",
  size,
}) => {
  return (
    <Button
      sx={{ minWidth: 0, padding: 0 }}
      onClick={onClick}
      className={className}
      disabled={disabled}
      style={{ backgroundColor: "orange" }}
      type={type}
      fullWidth={fullWidth}
      variant="contained"
      size={size}
      title={title}
    >
      {text}
    </Button>
  );
};

export default ButtonMUI;
