import { MenuItem } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import ButtonMUI from "../../../components/Atoms/Button";
import InputMUI from "../../../components/Atoms/InputMUI/InputMUI";
import SelectInputMUI from "../../../components/Atoms/Select/SelectInputMUI";
import Loader from "../../../components/common/Loader";
import {
  Duration,
  InputDateTimeFormat,
  UTCFormat,
} from "../../../components/common/time";
import { useApi } from "../../../hooks/useApi";
import useSupervisorStore from "../supervisorStoe";

const AnotherBreakDown = ({ ClosePopups, handleClose, userId }) => {
  const working_area_id = JSON.parse(
    localStorage.getItem("user")
  )?.working_area;

  const { error, setError, selectedVehicleData, setDataUpdate } =
    useSupervisorStore(
      (state) => ({
        error: state.error,
        setError: state.setError,
        selectedVehicleData: state.selectedVehicleData,
        setDataUpdate: state.setDataUpdate,
      }),
      shallow
    );

  const { get, post, patch, loading, data } = useApi();

  const initialFormVformValues = {
    break_down_reason: "",
    bk_opening_hr_meter: selectedVehicleData?.breakdown_closing_hr_meter,
    bk_closing_hr_meter: selectedVehicleData?.breakdown_closing_hr_meter,
    bk_opening_time: selectedVehicleData?.breakdown_closing_time,
    bk_closing_time: selectedVehicleData?.breakdown_closing_time,
  };

  const [formValues, setFormValues] = useState(initialFormVformValues);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      parseFloat(formValues?.bk_closing_hr_meter) -
        parseFloat(formValues?.bk_opening_hr_meter) >
      Duration(formValues?.bk_opening_time, formValues?.bk_closing_time)
    ) {
      setError("Total time should be greater than Total Hour Meter");
    } else if (
      parseFloat(formValues?.bk_opening_hr_meter) -
        parseFloat(selectedVehicleData?.breakdown_closing_hr_meter) >
      Duration(
        selectedVehicleData?.breakdown_closing_time,
        formValues?.bk_opening_time
      )
    ) {
      setError(
        "Difference between break down start hours and previous breadown closing hour should be less than start of breakdown start time and previous breakdown closing time"
      );
    } else {
      setError("");

      let payload = {
        closing_hr_meter: parseFloat(formValues?.bk_opening_hr_meter),
        closing_time: UTCFormat(formValues?.bk_opening_time),
      };

      let payload2 = {
        user: userId,
        working_area: working_area_id,
        vehicle: selectedVehicleData?.vehicle?._id,
        slab_rate: selectedVehicleData?.vehicle?.default_slab,
        opening_hr_meter: parseFloat(formValues?.bk_opening_hr_meter),
        opening_time: UTCFormat(formValues?.bk_opening_time),
      };

      let payload3 = {
        breakdown_reason: formValues?.break_down_reason,
        breakdown_opening_hr_meter: parseFloat(formValues?.bk_opening_hr_meter),
        breakdown_closing_hr_meter: parseFloat(formValues?.bk_closing_hr_meter),
        breakdown_opening_time: UTCFormat(formValues?.bk_opening_time),
        breakdown_closing_time: UTCFormat(formValues?.bk_closing_time),
      };

      try {
        let response;
        response = await patch(
          `supervisor-add-entry/update-closing/${selectedVehicleData?.vehicle?._id}`,
          payload
        );
        if (!response?.error) {
          response = await post("supervisor-add-entry/create", payload2);

          if (!response?.error) {
            response = await patch(
              `supervisor-add-entry/update/${selectedVehicleData?.vehicle?._id}`,
              payload3
            );
            if (!response?.error) {
              ClosePopups();
              setDataUpdate(event.timeStamp);
            }
          }
        }
      } catch (err) {
        setError(err);
      }
    }
  };

  useEffect(() => {
    async function getBreakdownOptions() {
      await get("breakdown-master/list");
    }

    getBreakdownOptions();
    handleClose();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Loader open={loading} />
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <SelectInputMUI
            name="break_down_reason"
            onChange={handleInputChange}
            placeholder="Break Down Reason"
            label="Break Down Reason"
            value={formValues?.break_down_reason}
            required
          >
            {data?.map((opt) => (
              <MenuItem value={opt?._id}>{opt?.name}</MenuItem>
            ))}
          </SelectInputMUI>
        </div>

        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            type="number"
            label="Opening hr meter"
            name="bk_opening_hr_meter"
            value={formValues?.bk_opening_hr_meter}
            onChange={handleInputChange}
            required
            min={selectedVehicleData?.breakdown_closing_hr_meter}
          />
        </div>
        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            type="number"
            label="Closing hr meter"
            name="bk_closing_hr_meter"
            value={formValues?.bk_closing_hr_meter}
            onChange={handleInputChange}
            required
            min={formValues?.bk_opening_hr_meter}
          />
        </div>
        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            type="number"
            label="Total hr meter"
            value={
              formValues?.bk_closing_hr_meter - formValues?.bk_opening_hr_meter
            }
            readOnly
          />
        </div>

        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            value={InputDateTimeFormat(formValues?.bk_opening_time)}
            onChange={handleInputChange}
            label="Opening time"
            name="bk_opening_time"
            type="datetime-local"
            step={60}
            required
            min={InputDateTimeFormat(
              selectedVehicleData?.breakdown_closing_time
            )}
            max={InputDateTimeFormat(moment())}
          />
        </div>
        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            value={InputDateTimeFormat(formValues?.bk_closing_time)}
            onChange={handleInputChange}
            label="Closing time"
            name="bk_closing_time"
            type="datetime-local"
            step={60}
            required
            min={InputDateTimeFormat(formValues?.bk_opening_time)}
            max={InputDateTimeFormat(moment())}
          />
        </div>
        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            type="number"
            label="Total breakdown hr meter"
            value={Duration(
              formValues?.bk_opening_time,
              formValues?.bk_closing_time
            )}
            min={
              formValues?.bk_closing_hr_meter - formValues?.bk_opening_hr_meter
            }
            readOnly
          />
        </div>
        <p className="text-orange-500">{error}</p>
      </div>
      <ButtonMUI fullWidth text="Save" type="submit" />
    </form>
  );
};

export default AnotherBreakDown;
