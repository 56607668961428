import React from "react";
import ButtonMUI from "./Atoms/Button";
import InputMUI from "./Atoms/InputMUI/InputMUI";
import { InputDateTimeFormat, fromDate } from "./common/time";

const DateRangePicker = ({ onSubmit }) => {
  return (
    <form
      onSubmit={onSubmit}
      className="flex flex-col lg:flex-row gap-2 items-center"
    >
      <InputMUI
        defaultValue={InputDateTimeFormat(fromDate(30))}
        type="datetime-local"
        step={60}
        name="fromDate"
      />

      <InputMUI
        defaultValue={InputDateTimeFormat(new Date())}
        type="datetime-local"
        step={60}
        name="toDate"
      />

      <ButtonMUI fullWidth text="Submit" type="submit" />
    </form>
  );
};

export default DateRangePicker;
