import { MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import { shallow } from "zustand/shallow";
import ButtonMUI from "../../../components/Atoms/Button";
import SelectInputMUI from "../../../components/Atoms/Select/SelectInputMUI";
import Loader from "../../../components/common/Loader";
import { useApi } from "../../../hooks/useApi";
import useSupervisorStore from "../supervisorStoe";

const ChangeWorkingAreaComponent = ({ handleClose, ClosePopups }) => {
  const { selectedVehicleData, setDataUpdate } = useSupervisorStore(
    (state) => ({
      selectedVehicleData: state.selectedVehicleData,
      setDataUpdate: state.setDataUpdate,
    }),
    shallow
  );

  const { get, patch, loading, data } = useApi();

  async function getWorkingAreas() {
    await get("working-area/list");
  }

  useEffect(() => {
    getWorkingAreas();
    handleClose();
  }, []);

  const HandleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    let payload = {
      working_area: data.get("working_area"),
    };

    const response = await patch(
      `vehicle/update-workingArea/${selectedVehicleData?.vehicle?._id}`,
      payload
    );

    if (!response?.error) {
      setDataUpdate(new Date().valueOf());
      ClosePopups();
    }
  };

  return (
    <form onSubmit={HandleSubmit} className="flex flex-col gap-4">
      <Loader open={loading} />
      <SelectInputMUI
        label="Change working area"
        placeholder="Change working area"
        required
        name="working_area"
        defaultValue={selectedVehicleData?.working_area?._id}
      >
        {data?.length > 0 &&
          data.map((wrk) => (
            <MenuItem key={wrk?._id} value={wrk?._id}>
              {wrk?.area_name}
            </MenuItem>
          ))}
      </SelectInputMUI>

      <ButtonMUI fullWidth text="Save" type="submit" />
    </form>
  );
};

export default ChangeWorkingAreaComponent;
