import TextField from "@mui/material/TextField";
import React from "react";

const InputMUI = ({
  value,
  min,
  max,
  type,
  name,
  onChange,
  label,
  disabled,
  readOnly,
  placeholder = "...",
  required,
  onKeyPress,
  error,
  textTransform = "normal",
  helperText,
  defaultValue,
  ref,
  autoComplete,
  className,
  maxLength,
  step = 0.001,
  multiline,
  rows,
}) => {
  return (
    <TextField
      className={className}
      ref={ref}
      defaultValue={defaultValue}
      error={error}
      required={required}
      label={label}
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      helperText={helperText}
      type={type}
      name={name}
      fullWidth
      onChange={onChange}
      onKeyPress={onKeyPress}
      InputProps={{
        readOnly: readOnly,
        inputProps: {
          max: max,
          min: min,
          style: {
            textTransform: textTransform,
          },
          maxLength: maxLength,
          step: step,
        },
      }}
      autoComplete={autoComplete}
      size="small"
      InputLabelProps={{
        shrink: true,
      }}
      multiline={multiline}
      rows={rows}
    />
  );
};

export default InputMUI;
