import React from "react";

const Footer = () => {
  return (
    <div className="flex z-[111111111] h-fit flex-row flex-wrap justify-center text-[0.7em] bg-[#d3cdca] text-center w-full  items-center p-0.5">
      {/* <span className="p-0 pl-4 pr-4 border-r-2 border-[#F07023] text-center">
        <a href="https://orangecells.com/privacy" target="_blank">
          Privacy
        </a>
      </span>
      <span className="p-0 pl-4 pr-4 border-r-2 border-[#F07023]">
        <a href="https://orangecells.com/terms" target="_blank">
          Terms
        </a>
      </span> */}
      <span className="p-0 pl-4">
        © 2022-present, Orangecells Innovation Lab Pvt. Ltd.
      </span>
    </div>
  );
};

export default Footer;
