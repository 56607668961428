import { ExpandMore } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import checkCircle from "../../assets/img/check-circle.png";
import ButtonMUI from "../../components/Atoms/Button";
import InputMUI from "../../components/Atoms/InputMUI/InputMUI";
import DateRangePicker from "../../components/DateRangePicker";
import Loader from "../../components/common/Loader";
import { LocalTime, UTCFormat, fromDate } from "../../components/common/time";
import { useApi } from "../../hooks/useApi";

const EntryExitDCRequestLogs = () => {
  const navigate = useNavigate();

  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [vehicleSearchtext, setVehicleSearchtext] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [selectedRow, SetselectedRow] = useState();

  const handleExpandClick = (item) => {
    setExpanded(!expanded);
    SetselectedRow(item._id);
  };

  const { get, loading, data } = useApi();

  const handleGet = async (e) => {
    let payload = {
      fromDate: fromDate(30),
      toDate: new Date(),
      diesel_chargeable: true,
    };

    if (e) {
      e.preventDefault();

      const data = new FormData(e.currentTarget);

      payload = {
        fromDate: UTCFormat(data.get("fromDate")),
        toDate: UTCFormat(data.get("toDate")),
        diesel_chargeable: true,
      };
    }
    if (payload) {
      const Dateparams = new URLSearchParams(payload).toString();

      await get(`store-dashboard/req-log-eec?${Dateparams}`);
    }
  };

  useEffect(() => {
    handleGet();
  }, []);

  useEffect(() => {
    const filtered =
      data &&
      Object.values(data)?.filter((vehicle) => {
        return [
          vehicle?.date,
          vehicle?.driverName,
          vehicle?.reg_no,
          vehicle?.fuel_vol,
          vehicle?.approved_by_store_manager,
          vehicle?.opening_hr_meter?.toFixed(0),
        ].some(
          (s) => s && s.toLowerCase().includes(vehicleSearchtext.toLowerCase())
        );
      });
    setFilteredVehicles(filtered);
  }, [data?.length, vehicleSearchtext]);

  const columns = [
    {
      headerName: "Status",
      field: "approved_by_store_manager",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => (
        <ButtonMUI
          size="small"
          text={
            e.row?.fuel_check_status === true
              ? "Completed"
              : e.row?.approved_by_store_manager
          }
        />
      ),
    },

    {
      headerName: "Action",
      field: "actions",
      type: "actions",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) =>
        e.row?.approved_by_store_manager === "approved" && (
          <ButtonMUI
            onClick={() =>
              navigate("/store/vehicle-requested-form/" + e.row?._id)
            }
            text={<img src={checkCircle} alt="" />}
          />
        ),
    },
    {
      field: "fuel_issue_time",
      headerName: "Requested time",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => LocalTime(e.row?.fuel_issue_time),
    },
    {
      field: "reg_no",
      headerName: "Vehicle",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "fuel_vol",
      headerName: "Fuel volume",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) =>
        e?.row?.fuel_vol?.toLowerCase() === "liter"
          ? e?.row?.liter_value
          : e?.row?.fuel_vol,
    },
    {
      field: "fuel_request_number",
      headerName: "Request number",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "opening_hr_meter",
      headerName: "Hour meter at time of fuel issue",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "userName",
      headerName: "Request raised by",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => (e.row.user?.userName ? e.row.user?.userName : "NA"),
    },

    {
      field: "store_approval_time",
      headerName: "Approval time at time of fuel issue",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) =>
        e.row.store_approval_time ? LocalTime(e.row.store_approval_time) : "NA",
    },
    {
      field: "store_approver_name",
      headerName: "Approver name at time of fuel issue",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  return (
    <>
      <Loader open={loading} />

      <DateRangePicker onSubmit={handleGet} />
      <div className="grid grid-cols-12 gap-2 py-3">
        <div className="col-span-12 lg:col-span-6">
          Entry/Exit diesel chargable list
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InputMUI
            defaultValue={vehicleSearchtext}
            type="text"
            onChange={(e) => setVehicleSearchtext(e.target.value)}
            placeholder="Search Vehicle by params"
          />
        </div>
      </div>

      <div className="hidden lg:block h-[90vh]">
        <DataGrid
          getRowId={(row) => row._id}
          rows={filteredVehicles?.length > 0 ? filteredVehicles : []}
          getRowClassName={(row) => row?.row.approved_by_store_manager}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </div>

      <div className="lg:hidden block h-[90vh]">
        {filteredVehicles?.map((item, i) => (
          <Card key={i} className={`${item?.approved_by_store_manager} my-2`}>
            <CardHeader
              avatar={
                <Avatar>
                  <LocalShippingIcon color="info" />
                </Avatar>
              }
              action={
                <>
                  <IconButton onClick={() => handleExpandClick(item)}>
                    <ExpandMore
                      expand={Boolean(expanded).toString()}
                      aria-expanded={Boolean(expanded).toString()}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </IconButton>
                  <Button
                    style={{
                      backgroundColor: "white",
                      cursor: "none",
                      fontWeight: 800,
                      marginRight: "8px",
                    }}
                  >
                    {item?.fuel_check_status === true
                      ? "Completed"
                      : item?.approved_by_store_manager}
                  </Button>
                </>
              }
              title={item?.vehicle?.reg_no}
              subheader={item?.vehicle?.vehicle_type?.name}
            />

            {item?._id === selectedRow && (
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography paragraph>
                    <span className="text-orange-700 pr-2">
                      Fuel request time:
                    </span>
                    {LocalTime(item?.fuel_issue_time)}
                  </Typography>

                  <Typography paragraph>
                    <span className="text-orange-700 pr-2">Driver:</span>
                    {item?.driverName}
                  </Typography>
                  <Typography paragraph>
                    <span className="text-orange-700 pr-2">
                      Requested Volume:
                    </span>
                    {item?.fuel_vol}
                  </Typography>

                  <Typography paragraph>
                    <span className="text-orange-700 pr-2">
                      Hour meter at time of fuel issue:
                    </span>
                    {item?.opening_hr_meter}
                  </Typography>

                  <Typography paragraph>
                    <span className="text-orange-700 pr-2">
                      Request raised by:
                    </span>
                    {item?.user?.userName}
                  </Typography>

                  <Typography paragraph>
                    <span className="text-orange-700 pr-2">
                      Approval time at time of fuel issue:
                    </span>
                    {LocalTime(item?.store_approval_time)}
                  </Typography>

                  <Typography paragraph>
                    <span className="text-orange-700 pr-2">
                      Approver name at time of fuel issue:
                    </span>
                    {LocalTime(item?.store_approver_name)}
                  </Typography>

                  {item?.approved_by_store_manager === "approved" && (
                    <Button
                      disabled={item?.fuel_check_status}
                      style={{ backgroundColor: "white" }}
                      onClick={() =>
                        navigate("/store/vehicle-requested-form/" + item?._id)
                      }
                    >
                      <img src={checkCircle} alt="" />
                    </Button>
                  )}
                </CardContent>
              </Collapse>
            )}
          </Card>
        ))}
      </div>
    </>
  );
};

export default EntryExitDCRequestLogs;
