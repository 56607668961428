import { ExpandMore } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { shallow } from "zustand/shallow";
import ButtonMUI from "../../../../components/Atoms/Button";
import { LocalTime } from "../../../../components/common/time";
import useSupervisorStore from "../../supervisorStoe";

const VehicleLIstTable = ({ target, handleClick }) => {
  const {
    selectedVehicleData,
    filteredData,
    setSelectVehicleId,
    SetselectedVehicleData,
  } = useSupervisorStore(
    (state) => ({
      selectedVehicleData: state.selectedVehicleData,
      filteredData: state.filteredData,
      setSelectVehicleId: state.setSelectVehicleId,
      SetselectedVehicleData: state.SetselectedVehicleData,
    }),
    shallow
  );

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = (item) => {
    setExpanded(!expanded);
    SetselectedVehicleData(item);
  };

  const columns = [
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => (
        <>
          <span ref={target}>
            <ButtonMUI
              onClick={(event) => {
                handleClick(event);
                setSelectVehicleId(e.row?.vehicle?._id);
                SetselectedVehicleData(e.row);
              }}
              text={<MoreVertIcon color="action" />}
            />
          </span>
        </>
      ),
    },
    {
      field: "reg_no",
      headerName: "Registration No",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => e?.row?.vehicle?.reg_no,
    },
    {
      field: "vendor",
      headerName: "Vendor Name",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => e?.row?.vehicle?.vendor?.vendor_name,
    },
    {
      field: "vehicle_type",
      headerName: "Vehicle Type",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => e?.row?.vehicle?.vehicle_type?.name,
    },

    {
      field: "diesel_chargable",
      headerName: "Diesel chargable",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => (e?.row?.vehicle?.diesel_chargable ? "Yes" : "No"),
    },

    {
      field: "opening_time",
      headerName: "Last opening time",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => LocalTime(e?.row?.opening_time),
    },
    {
      field: "closing_time",
      headerName: "Last closing time",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) =>
        e?.row?.closing_time
          ? LocalTime(e?.row?.closing_time)
          : "Not closed yet",
    },
    {
      field: "opening_hr_meter",
      headerName: "Last opening hour meter",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "closing_hr_meter",
      headerName: "Last closing hour meter",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (e) =>
        e?.row?.closing_hr_meter ? e?.row?.closing_hr_meter : "Not closed yet",
    },
  ];

  const getRowClassName = (params) => {
    if (params?.row?.closing_time && params?.row.closing_hr_meter) {
      return "pending";
    } else {
      return "approved";
    }
  };

  return (
    <>
      <div className="hidden lg:block h-[70vh]">
        <DataGrid
          rows={filteredData?.length > 0 ? filteredData : []}
          columns={columns}
          getRowId={(row) => row._id}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowClassName={getRowClassName}
          disableColumnMenu
        />
      </div>
      <div className="lg:hidden block h-[90vh]">
        {filteredData?.length > 0 &&
          filteredData?.map((item, i) => (
            <Card
              key={i}
              sx={{
                backgroundColor: !item?.closing_hr_meter
                  ? "lightgreen"
                  : "#eebc4e",
              }}
              className="my-2"
            >
              <CardHeader
                avatar={
                  <Avatar>
                    <LocalShippingIcon color="info" />
                  </Avatar>
                }
                action={
                  <>
                    <IconButton onClick={() => handleExpandClick(item)}>
                      <ExpandMore
                        expand={Boolean(expanded).toString()}
                        aria-expanded={Boolean(expanded).toString()}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </IconButton>
                    <IconButton
                      ref={target}
                      onClick={(event) => {
                        handleClick(event);
                        setSelectVehicleId(item?.vehicle?._id);
                        SetselectedVehicleData(item);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </>
                }
                title={item?.vehicle?.reg_no}
                subheader={item?.vehicle?.vehicle_type?.name}
              />

              {item?._id === selectedVehicleData?._id && (
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">
                        {" "}
                        Vendor name:
                      </span>
                      {item?.vehicle?.vendor?.vendor_name}
                    </Typography>
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">
                        Diesel chargable:
                      </span>
                      {item?.vehicle?.diesel_chargable ? "Yes" : "No"}
                    </Typography>
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">
                        Last opening time:
                      </span>
                      {LocalTime(item?.opening_time)}
                    </Typography>
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">
                        Last closing time:
                      </span>
                      {LocalTime(item?.closing_time)}
                    </Typography>
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">
                        Last opening hour meter:
                      </span>
                      {item?.opening_hr_meter}
                    </Typography>

                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">
                        Last closing hour meter:
                      </span>
                      {item?.closing_hr_meter}
                    </Typography>
                  </CardContent>
                </Collapse>
              )}
            </Card>
          ))}
      </div>
    </>
  );
};

export default VehicleLIstTable;
