import { MenuItem } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import ButtonMUI from "../../../components/Atoms/Button";
import InputMUI from "../../../components/Atoms/InputMUI/InputMUI";
import SelectInputMUI from "../../../components/Atoms/Select/SelectInputMUI";
import Loader from "../../../components/common/Loader";
import {
  Duration,
  InputDateTimeFormat,
  UTCFormat,
} from "../../../components/common/time";
import { useApi } from "../../../hooks/useApi";
import useSupervisorStore from "../supervisorStoe";

const BreakTimeComponent = ({ handleClose, ClosePopups }) => {
  const { error, setError, selectedVehicleData, setDataUpdate } =
    useSupervisorStore(
      (state) => ({
        error: state.error,
        setError: state.setError,
        selectedVehicleData: state.selectedVehicleData,
        setDataUpdate: state.setDataUpdate,
      }),
      shallow
    );

  const { get, patch, loading, data } = useApi();

  const initialState = {
    break_down_reason: "",
    opening_hr_meter: selectedVehicleData?.opening_hr_meter,
    closing_hr_meter: selectedVehicleData?.opening_hr_meter,
    opening_time: selectedVehicleData?.opening_time,
    closing_time: selectedVehicleData?.opening_time,
  };

  const [formValues, setFormValues] = useState(initialState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  useEffect(() => {
    async function getBreakdownOptions() {
      await get("breakdown-master/list");
    }

    getBreakdownOptions();
    handleClose();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      parseFloat(formValues?.closing_hr_meter) -
        parseFloat(formValues?.opening_hr_meter) >
      Duration(formValues?.opening_time, formValues?.closing_time)
    ) {
      setError("Total time should be greater than Total Hour Meter");
    } else if (
      parseFloat(formValues?.opening_hr_meter) -
        parseFloat(selectedVehicleData?.opening_hr_meter) >
      Duration(selectedVehicleData?.opening_time, formValues?.opening_time)
    ) {
      setError(
        "Difference between break down start hours and vehicle opening hour should be less than start of breakdown start time and vehicle  opening time"
      );
    } else {
      setError("");

      let Payload = {
        breakdown_reason: formValues.break_down_reason,
        breakdown_opening_hr_meter: parseFloat(formValues.opening_hr_meter),
        breakdown_closing_hr_meter: parseFloat(formValues.closing_hr_meter),
        breakdown_opening_time: UTCFormat(formValues.opening_time),
        breakdown_closing_time: UTCFormat(formValues.closing_time),
      };

      const response = await patch(
        `supervisor-add-entry/breakdown-update/${selectedVehicleData?.vehicle?._id}`,
        Payload
      );

      if (!response?.error) {
        ClosePopups();
        setDataUpdate(new Date().valueOf());
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Loader open={loading} />
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <SelectInputMUI
            name="break_down_reason"
            onChange={handleInputChange}
            label="Break Down Reason"
            placeholder="Break Down Reason"
            value={formValues.break_down_reason}
            required
          >
            {data?.map((opt, i) => (
              <MenuItem key={i} value={opt?._id}>
                {opt?.name}
              </MenuItem>
            ))}
          </SelectInputMUI>
        </div>

        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            label="Opening Hour Meter"
            type="number"
            name="opening_hr_meter"
            value={formValues.opening_hr_meter}
            onChange={handleInputChange}
            required
            min={selectedVehicleData?.opening_hr_meter}
          />
        </div>
        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            label="Closing Hour Meter"
            type="number"
            name="closing_hr_meter"
            value={formValues.closing_hr_meter}
            onChange={handleInputChange}
            required
            min={formValues.opening_hr_meter}
          />
        </div>
        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            type="number"
            label="Total Hour Meter"
            name="totalHrmeter"
            value={formValues.closing_hr_meter - formValues.opening_hr_meter}
            required
            readOnly
          />
        </div>

        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            name="opening_time"
            label="Opening Hour Time"
            value={InputDateTimeFormat(formValues?.opening_time)}
            onChange={handleInputChange}
            min={InputDateTimeFormat(selectedVehicleData?.opening_time)}
            max={InputDateTimeFormat(moment())}
            type="datetime-local"
            step={60}
            required
          />
        </div>

        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            name="closing_time"
            label="Closing Hour Time"
            value={InputDateTimeFormat(formValues?.closing_time)}
            onChange={handleInputChange}
            min={InputDateTimeFormat(formValues?.opening_time)}
            max={InputDateTimeFormat(moment())}
            type="datetime-local"
            step={60}
            required
          />
        </div>

        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            type="number"
            name="total_time"
            label="Total Hour Time"
            value={Duration(formValues?.opening_time, formValues?.closing_time)}
            onChange={handleInputChange}
            readOnly
            required
          />
        </div>
        <p className="text-red-600">{error}</p>
      </div>
      <ButtonMUI fullWidth text="Save" type="submit" />
    </form>
  );
};

export default BreakTimeComponent;
