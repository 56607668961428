import { createWithEqualityFn } from "zustand/traditional";

const DashboardStore = createWithEqualityFn((set) => ({
  dashboardData: {},
  error: "",
  loading: "",
  searchText: "",
  vehicleListFilteredData: [],
  vendorListFilteredData: [],
  vehicleList: [],
  vendorList: [],
  selectVehicleData: "",
  timeStamp: "",

  setDashboardData: (newData) => set((state) => ({ dashboardData: newData })),

  setError: (newError) => set((state) => ({ error: newError })),

  setLoading: (newLoading) => set((state) => ({ loading: newLoading })),

  setSearchText: (newSearchText) =>
    set((state) => ({ searchText: newSearchText })),

  setvehicleListFilteredData: (newData) =>
    set((state) => ({ vehicleListFilteredData: newData })),

  setvendorListFilteredData: (newData) =>
    set((state) => ({ vendorListFilteredData: newData })),

  setVehicleList: (newData) => set((state) => ({ vehicleList: newData })),

  setVendorList: (newData) => set((state) => ({ vendorList: newData })),

  setSelectVehicleData: (newData) =>
    set((state) => ({ selectVehicleData: newData })),

  setTimeStamp: (newData) => set((state) => ({ timeStamp: newData })),

  resetData: () =>
    set(() => ({
      dashboardData: {},
      vehicleListFilteredData: [],
      vendorListFilteredData: [],
      vehicleList: [],
      vendorList: [],
      selectVehicleData: "",
    })),
}));

export default DashboardStore;
