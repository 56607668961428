import React from "react";
import VehicleListSearchSection from "../components/VehicleListSearchSection";
import VehicleListTable from "../components/VehicleListTable";

const VehicleList = () => {
  return (
    <>
      <VehicleListSearchSection />
      <VehicleListTable />
    </>
  );
};

export default VehicleList;
