import { CircularProgress } from "@mui/material";
import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import HomePath from "./HomePath";
import Layout from "./Layout/Layout";
import ProtectedRoute from "./ProtectedRoute";
import BulkUpload from "./components/CSV/BulkUpload";

import SupervisorDNCRequestLogs from "./pages/StoreDashboard/SupervisorDNCRequestLogs";
import SupervisorDNCCompletedLogs from "./pages/StoreDashboard/SupervisorDNCCompletedLogs";
import EntryExitDCRequestLogs from "./pages/StoreDashboard/EntryExitDCRequestLogs";
import EntryExitDCCompletedLogs from "./pages/StoreDashboard/EntryExitDCCompletedLogs";

const tenantIdEnv = process.env.REACT_APP_TENANT_ID;

const EditVehicleLog = lazy(() =>
  import("./pages/Supervisor/SupervisorDashboard/EditVehicleLog")
);

const App = lazy(() => import("./App"));

const AdminDashboardPage = lazy(() =>
  import("./pages/AdminDashboard/AdminDashboardPage")
);
const Users = lazy(() => import("./pages/AdminDashboard/Users/index"));
const AddUser = lazy(() => import("./pages/AdminDashboard/Users/AddUser"));
const Shift = lazy(() => import("./pages/AdminDashboard/Shift/index"));
const BreakDownReason = lazy(() =>
  import("./pages/AdminDashboard/BreakDownReason/index")
);
const AddPlant = lazy(() => import("./pages/AdminDashboard/Plant/AddPlant"));
const Plant = lazy(() => import("./pages/AdminDashboard/Plant"));

const AddDriver = lazy(() => import("./pages/AdminDashboard/Driver/AddDriver"));
const WorkingArea = lazy(() =>
  import("./pages/AdminDashboard/WorkingArea/index")
);

const Logger = lazy(() => import("./pages/Login/Logger"));

const Driver = lazy(() => import("./pages/AdminDashboard/Driver/index"));

const ChangePassword = lazy(() => import("./pages/Login/ChangePassword"));

const Login = lazy(() => import("./pages/Login/Login"));

const SignUp = lazy(() => import("./pages/Login/SignUp"));

const VendorList = lazy(() => import("./pages/Dashboard/Vendor/VendorList"));
const AddVendor = lazy(() => import("./pages/Dashboard/Vendor/AddVendor"));
const VendorDetails = lazy(() =>
  import("./pages/Dashboard/Vendor/VendorDetails")
);

const VehicleList = lazy(() => import("./pages/Dashboard/Vehicle/VehicleList"));
const AddVehicle = lazy(() => import("./pages/Dashboard/Vehicle/AddVehicle"));
const VehicleDetails = lazy(() =>
  import("./pages/Dashboard/Vehicle/VehicleDetails")
);

const VehicleTypeMaster = lazy(() =>
  import("./pages/Dashboard/VehicleType/VehicleTypeMaster")
);

const SupervisorDashboard = lazy(() =>
  import("./pages/Supervisor/SupervisorDashboard/SupervisorDashboard")
);
const VehicleLogList = lazy(() =>
  import("./pages/Supervisor/SupervisorDashboard/VehicleLogList")
);

const ManagerDashboard = lazy(() => import("./pages/Manager/ManagerDashboard"));

const DiselPriceList = lazy(() =>
  import("./pages/StoreDashboard/DiselPriceList")
);

const EntryExitDCFuelIssueForm = lazy(() =>
  import("./pages/StoreDashboard/EntryExitDCFuelIssueForm")
);
const SupervisorDNCFuelIssueForm = lazy(() =>
  import("./pages/StoreDashboard/SupervisorDNCFuelIssueForm")
);

const AccountantDashboard = lazy(() =>
  import("./pages/AccountantDash/AccountantDashboard")
);
const OldReportlist = lazy(() =>
  import("./pages/AccountantDash/OldReportlist")
);
const ReportDetails = lazy(() =>
  import("./pages/AccountantDash/ReportDetails")
);

const NotFound = lazy(() => import("./pages/NotFound"));

const RoutesPage = () => {
  return (
    <Layout>
      <Suspense
        fallback={
          <div className="h-screen w-full flex justify-center items-center">
            <CircularProgress />
          </div>
        }
      >
        <Routes>
          <Route exact path="/login" element={<Login />} />

          {
            /*
              Do not allow tenant signup if tenant info is present in .env file, which means it
              is part of a standalone deployment
            */

            !tenantIdEnv && <Route exact path="/signup" element={<SignUp />} />
          }

          <Route exact path="/logger/:id" element={<Logger />} />
          <Route
            path="/bulkUpload/:id"
            element={
              <ProtectedRoute>
                <BulkUpload />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <App />
              </ProtectedRoute>
            }
          >
            <Route
              index
              element={<ProtectedRoute>{HomePath()} </ProtectedRoute>}
            />

            <Route path="logistic">
              <Route
                path="vehicle-list"
                element={
                  <ProtectedRoute>
                    <VehicleList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="vendor-list"
                element={
                  <ProtectedRoute>
                    <VendorList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="addVehicle"
                element={
                  <ProtectedRoute>
                    <AddVehicle />
                  </ProtectedRoute>
                }
              />
              <Route
                path="editVehicle/:id"
                element={
                  <ProtectedRoute>
                    <AddVehicle />
                  </ProtectedRoute>
                }
              />
              <Route
                path="reActive/:id"
                element={
                  <ProtectedRoute>
                    <AddVehicle />
                  </ProtectedRoute>
                }
              />

              <Route
                path="vehicle-types"
                element={
                  <ProtectedRoute>
                    <VehicleTypeMaster />
                  </ProtectedRoute>
                }
              />
              <Route
                path="add-vendor"
                element={
                  <ProtectedRoute>
                    <AddVendor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="edit_vendor/:id"
                element={
                  <ProtectedRoute>
                    <AddVendor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="vehicleDetails/:id"
                element={
                  <ProtectedRoute>
                    <VehicleDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="vendor-details/:id"
                element={
                  <ProtectedRoute>
                    <VendorDetails />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route path="supervisor">
              <Route
                path="dashboard"
                element={
                  <ProtectedRoute>
                    <SupervisorDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="vehicle-logs/:id"
                element={
                  <ProtectedRoute>
                    <VehicleLogList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="vehicle-logs/edit_log/:id"
                element={
                  <ProtectedRoute>
                    <EditVehicleLog />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path="manager"
              element={
                <ProtectedRoute>
                  <ManagerDashboard />
                </ProtectedRoute>
              }
            />

            <Route path="store">
              <Route
                path="requested-logs"
                element={
                  <ProtectedRoute>
                    <SupervisorDNCRequestLogs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="completed-logs"
                element={
                  <ProtectedRoute>
                    <SupervisorDNCCompletedLogs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="completed-vehicle"
                element={
                  <ProtectedRoute>
                    <EntryExitDCCompletedLogs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="requested-vehicle"
                element={
                  <ProtectedRoute>
                    <EntryExitDCRequestLogs />
                  </ProtectedRoute>
                }
              />

              <Route
                path="update-log-form/:id"
                element={
                  <ProtectedRoute>
                    <SupervisorDNCFuelIssueForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="vehicle-requested-form/:id"
                element={
                  <ProtectedRoute>
                    <EntryExitDCFuelIssueForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="edit_DC_log/:id"
                element={
                  <ProtectedRoute>
                    <EntryExitDCFuelIssueForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="edit_NDC_log/:id"
                element={
                  <ProtectedRoute>
                    <SupervisorDNCFuelIssueForm />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path="accountant"
              element={
                <ProtectedRoute>
                  <AccountantDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="old-report"
              element={
                <ProtectedRoute>
                  <OldReportlist />
                </ProtectedRoute>
              }
            />
            <Route
              path="old-report/:id"
              element={
                <ProtectedRoute>
                  <OldReportlist />
                </ProtectedRoute>
              }
            />
            <Route
              path="report-details/:id"
              element={
                <ProtectedRoute>
                  <ReportDetails />
                </ProtectedRoute>
              }
            />

            <Route path="admin">
              <Route
                path="dashboard"
                element={
                  <ProtectedRoute>
                    <AdminDashboardPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="driver"
                element={
                  <ProtectedRoute>
                    <Driver />
                  </ProtectedRoute>
                }
              />
              <Route
                path="driver/add_driver"
                element={
                  <ProtectedRoute>
                    <AddDriver />
                  </ProtectedRoute>
                }
              />
              <Route
                path="driver/edit_driver/:id"
                element={
                  <ProtectedRoute>
                    <AddDriver />
                  </ProtectedRoute>
                }
              />
              <Route
                path="driver/view_driver/:id"
                element={
                  <ProtectedRoute>
                    <AddDriver />
                  </ProtectedRoute>
                }
              />

              <Route
                path="plant"
                element={
                  <ProtectedRoute>
                    <Plant />
                  </ProtectedRoute>
                }
              />

              <Route
                path="plant/addPlant"
                element={
                  <ProtectedRoute>
                    <AddPlant />
                  </ProtectedRoute>
                }
              />
              <Route
                path="plant/editPlant/:id"
                element={
                  <ProtectedRoute>
                    <AddPlant />
                  </ProtectedRoute>
                }
              />
              <Route
                path="plant/viewPlant/:id"
                element={
                  <ProtectedRoute>
                    <AddPlant />
                  </ProtectedRoute>
                }
              />

              <Route
                path="user"
                element={
                  <ProtectedRoute>
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                path="user/add_user"
                element={
                  <ProtectedRoute>
                    <AddUser />
                  </ProtectedRoute>
                }
              />
              <Route
                path="user/edit_user/:id"
                element={
                  <ProtectedRoute>
                    <AddUser />
                  </ProtectedRoute>
                }
              />
              <Route
                path="user/view_user/:id"
                element={
                  <ProtectedRoute>
                    <AddUser />
                  </ProtectedRoute>
                }
              />

              <Route
                path="add_shift"
                element={
                  <ProtectedRoute>
                    <Shift />
                  </ProtectedRoute>
                }
              />
              <Route
                path="add_working_area"
                element={
                  <ProtectedRoute>
                    <WorkingArea />
                  </ProtectedRoute>
                }
              />
              <Route
                path="add_break_down_reason"
                element={
                  <ProtectedRoute>
                    <BreakDownReason />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path="disel-price"
              element={
                <ProtectedRoute>
                  <DiselPriceList />
                </ProtectedRoute>
              }
            />

            <Route
              path="change_password"
              element={
                <ProtectedRoute>
                  <ChangePassword />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};
export default RoutesPage;
