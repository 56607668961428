import { createWithEqualityFn } from "zustand/traditional";
import {
  InputDate,
  firstDayOfLastMonth,
  lastDayOfLastMonth,
} from "../../../components/common/time";

const useAccountantStore = createWithEqualityFn((set) => ({
  data: "",
  error: "",
  loading: "",
  searchText: "",
  filteredData: [],
  dates: {
    startDate: InputDate(firstDayOfLastMonth) + "T06:00",
    endDate: InputDate(lastDayOfLastMonth) + "T05:59",
  },
  hireCharges: 0,
  refresh: null,
  diesel_rate: 0,
  deductionAmount: 0,
  allVehicles: [],
  values: [],

  UpdateDates: (newDates) => set((state) => ({ dates: newDates })),

  setValues: (newData) => set((state) => ({ values: [newData] })),

  setAllVehicles: (newData) => set((state) => ({ allVehicles: newData })),

  setData: (newData) => set((state) => ({ data: newData })),

  setError: (newError) => set((state) => ({ error: newError })),

  setLoading: (newLoading) => set((state) => ({ loading: newLoading })),

  setSearchText: (newSearchText) =>
    set((state) => ({ searchText: newSearchText })),

  setFilteredData: (newData) => set((state) => ({ filteredData: newData })),

  setHireCharges: (value) =>
    set((state) => ({
      hireCharges: value,
    })),

  setDieselRate: (value) =>
    set((state) => ({
      diesel_rate: value,
    })),

  setDeductionAmount: (value) =>
    set((state) => ({
      deductionAmount: value,
    })),

  setRefresh: (value) =>
    set((state) => ({
      refresh: value,
    })),

  resetData: () =>
    set(() => ({
      allVehicles: [],
      error: "",
      loading: "",
      searchText: "",
      filteredData: "",
    })),
}));

export default useAccountantStore;
