import axios from "axios";
import { useEffect, useState } from "react";
import useToast from "./Toast/useToast";

const baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("tenantId")?.replace(/['"]+/g, "");

    if (token) {
      try {
        config.headers["tenant-id"] = token;
      } catch (e) {
        console.log(e);
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const useApi = () => {
  const { showToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  const get = async (endPoint, headers) => {
    const url = `${baseURL}/${endPoint}`;

    try {
      setLoading(true);

      const response = await axios.get(url, headers);
      if (response.data?.error) {
        showToast(response.data?.message, "error");
        setError(response.data?.message);
      } else {
        setData(response.data?.data);
      }
      return response?.data;
    } catch (error) {
      showToast(
        error?.response?.data?.message || "Error in GET request",
        "error"
      );
      setError(error?.response?.data?.message);

      return error?.response?.data;
    } finally {
      setLoading(false);
    }
  };

  const post = async (endPoint, data, headers) => {
    const url = `${baseURL}/${endPoint}`;

    setLoading(true);

    try {
      const response = await axios.post(url, data, headers);
      if (response.data?.error) {
        showToast(response.data?.message, "error");
        setError(response.data?.message);
      } else {
        showToast(response.data?.message, "success");
      }
      return response?.data;
    } catch (error) {
      showToast(
        error?.response?.data?.message || "Error in POST request",
        "error"
      );

      setError(error?.response?.data?.message);
      return error?.response?.data;
    } finally {
      setLoading(false);
    }
  };

  const patch = async (endPoint, data) => {
    const url = `${baseURL}/${endPoint}`;

    setLoading(true);

    try {
      const response = await axios.patch(url, data);
      if (response.data?.error) {
        showToast(response.data?.message, "error");
        setError(response.data?.message);
      } else {
        showToast(response.data?.message, "success");
      }
      return response?.data;
    } catch (error) {
      showToast(
        error?.response?.data?.message || "Error in PATCH request",
        "error"
      );
      setError(error?.response?.data?.message);
      return error?.response?.data;
    } finally {
      setLoading(false);
    }
  };

  const del = async (endPoint) => {
    const url = `${baseURL}/${endPoint}`;

    setLoading(true);

    try {
      const response = await axios.delete(url);
      if (response.data?.error) {
        showToast(response.data?.message, "error");
        setError(response.data?.message);
      } else {
        showToast(response.data?.message, "success");
      }
      return response?.data;
    } catch (error) {
      showToast(
        error?.response?.data?.message || "Error in DELETE request",
        "error"
      );
      setError(error?.response?.data?.message);
      return error?.response?.data;
    } finally {
      setLoading(false);
    }
  };

  const put = async (endPoint, data) => {
    const url = `${baseURL}/${endPoint}`;

    setLoading(true);

    try {
      const response = await axios.put(url, data);
      if (response.data?.error) {
        showToast(response.data?.message, "error");
        setError(response.data?.message);
      } else {
        showToast(response.data?.message, "success");
      }
      return response?.data;
    } catch (error) {
      showToast(
        error?.response?.data?.message || "Error in put request",
        "error"
      );
      setError(error?.response?.data?.message);
      return error?.response?.data;
    } finally {
      setLoading(false);
    }
  };

  return { get, post, put, patch, del, loading, error, data };
};

const useApiData = (endPoint, headers) => {
  const url = `${baseURL}/${endPoint}`;

  const { showToast } = useToast();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url, headers);
        if (response.data?.error) {
          showToast(response.data?.message, "error");
          setError(response.data?.message);
        } else {
          setData(response.data?.data);
        }
      } catch (error) {
        showToast(
          error?.response?.data?.message || "Error in GET request",
          "error"
        );
        setError(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [endPoint]);

  return { data, loading, error };
};

export default useApiData;
