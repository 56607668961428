import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const menu = [
  {
    name: "Accountant dashboard",
    link: "/accountant",
    icon: "person",
  },
  {
    name: "Old report",
    link: "/old-report",
    icon: "truck",
  },
  {
    name: "Disel Price",
    link: "/disel-price",
  },
  {
    name: "Change password",
    link: "/change_password",
  },
];

const AccountantMenu = () => {
  const pathname = useLocation();
  return (
    <div className="w-full">
      <List>
        {menu.map((text, index) => (
          <Link to={text.link} key={index}>
            <ListItem
              sx={{
                backgroundColor:
                  pathname.pathname === text.link ? "orange" : "",
              }}
              key={index}
              disablePadding
            >
              <ListItemButton>
                <div className="flex gap-1 items-center">
                  {/* {Icon(text?.icon)} */}
                  <ListItemText primary={text.name} />
                </div>
              </ListItemButton>
            </ListItem>
            <Divider />
          </Link>
        ))}
      </List>
    </div>
  );
};

export default AccountantMenu;
