import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import CommonLayout from ".";

const Layout = ({ children }) => {
  const pathname = useLocation();

  const CurrentLayout = () => {
    if (
      pathname.pathname.includes("login") ||
      pathname.pathname.includes("signup") ||
      pathname.pathname.includes("logger")
    ) {
      return children;
    } else {
      return <CommonLayout>{children}</CommonLayout>;
    }
  };

  return <Fragment>{CurrentLayout()}</Fragment>;
};

export default Layout;
