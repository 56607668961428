import { Box, Modal } from "@mui/material";

const Popup = ({ width = 400, open, onClose, title, children }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <h4> {title}</h4>
        {children}
      </Box>
    </Modal>
  );
};

export default Popup;
