import { Delete, Edit, ExpandMore } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { shallow } from "zustand/shallow";
import InputMUI from "../../components/Atoms/InputMUI/InputMUI";
import DateRangePicker from "../../components/DateRangePicker";
import Loader from "../../components/common/Loader";
import { LocalTime, UTCFormat, fromDate } from "../../components/common/time";
import { useApi } from "../../hooks/useApi";
import Store from "../../store/loggerStore";
import Popup from "../../components/MUI/Popup";
import ButtonMUI from "../../components/Atoms/Button";

const SupervisorDNCCompletedLogs = () => {
  const userType = JSON.parse(localStorage.getItem("user"))?.user_type?.type;

  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [vehicleSearchtext, setVehicleSearchtext] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [selectedRow, SetselectedRow] = useState();
  const [selectedId, setSelectedId] = useState(false);

  const handleExpandClick = (item) => {
    setExpanded(!expanded);
    SetselectedRow(item._id);
  };

  const { get, del, loading, data } = useApi();

  const handleGet = async (e) => {
    let payload = {
      fromDate: fromDate(30),
      toDate: new Date(),
      diesel_chargeable: false,
    };

    if (e) {
      e.preventDefault();

      const data = new FormData(e.currentTarget);

      payload = {
        fromDate: UTCFormat(data.get("fromDate")),
        toDate: UTCFormat(data.get("toDate")),
        diesel_chargeable: false,
      };
    }
    if (userType) {
      const Dateparams = new URLSearchParams(payload).toString();

      await get(`store-dashboard/completed-list?${Dateparams}`);
    }
  };

  useEffect(() => {
    handleGet();
  }, []);

  useEffect(() => {
    const filtered =
      data &&
      Object.values(data)?.filter((vehicle) => {
        return [
          vehicle?.vehicle?.reg_no,
          vehicle?.fuel_issue_time,
          vehicle?.driver_name,
          vehicle?.userName,
        ].some(
          (s) => s && s?.toLowerCase().includes(vehicleSearchtext.toLowerCase())
        );
      });
    setFilteredVehicles(filtered);
  }, [data?.length, vehicleSearchtext]);

  const deleteFuelEntry = async () => {
    const response = await del(`/supervisor-add-entry/delete/${selectedId}`);

    if (!response?.error) {
      handleGet();
      setSelectedId(false);
    }
  };

  const columns = [
    {
      headerName: "Status",
      field: "fuel_check_status",
      width: 150,
      renderCell: (e) => (
        <Button style={{ backgroundColor: "white", fontWeight: 800 }}>
          {e.row?.fuel_check_status ? "Completed" : "Requested"}
        </Button>
      ),
    },
    {
      headerName: "Action",
      field: "actions",
      type: "actions",
      width: 150,

      renderCell: (e) => (
        <>
          <IconButton onClick={() => setSelectedId(e.row?._id)}>
            <Delete />
          </IconButton>

          <Link to={`/store/edit_NDC_log/${e.row?._id}`}>
            <Edit />
          </Link>
        </>
      ),
    },
    {
      field: "vehicleRegNo",
      headerName: "Vehicle Name",
      width: 190,
      renderCell: (e) => e.row?.vehicle?.reg_no,
    },
    {
      field: "fuel_issue_time",
      headerName: "Time of fill",
      width: 200,
      renderCell: (e) => LocalTime(e.row?.fuel_issue_time),
    },
    {
      field: "fuel_vol",
      headerName: "Requested volume",
      width: 180,
      renderCell: (e) =>
        e?.row?.fuel_vol?.toLowerCase() === "liter"
          ? e?.row?.liter_value
          : e?.row?.fuel_vol,
    },
    {
      field: "revised_fuel_vol",
      headerName: "Filled volume",
      width: 180,
      hideable: true,
    },
    {
      field: "fuel_request_number",
      headerName: "Request number",
      width: 190,
    },
    {
      field: "revised_hour_meter",
      headerName: "Hour meter reading",
      width: 160,
      align: "center",
    },

    {
      field: "driver_name",
      headerName: "Driver Name",
      width: 200,
      renderCell: (e) => e?.row?.vehicle?.temp_driver,
    },
  ];

  return (
    <>
      <Loader open={loading} />

      <DateRangePicker onSubmit={handleGet} />

      <div className="grid grid-cols-12 items-center gap-2 py-3">
        <div className="col-span-12 lg:col-span-6">
          Supervisor request completed log
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InputMUI
            defaultValue={vehicleSearchtext}
            type="text"
            onChange={(e) => setVehicleSearchtext(e.target.value)}
            placeholder="Search Vehicle by params"
          />
        </div>
      </div>

      <div className="hidden lg:block h-[90vh]">
        <DataGrid
          getRowId={(row) => row._id}
          rows={filteredVehicles?.length > 0 ? filteredVehicles : []}
          columns={columns}
          getRowClassName={(row) =>
            row?.row.fuel_check_status === true ? "approved" : "pending"
          }
          pageSize={5}
          columnVisibilityModel={{ actions: userType === "Store Hod" }}
          rowsPerPageOptions={[5]}
        />
      </div>

      <div className="lg:hidden block h-[90vh]">
        {filteredVehicles?.map((item, i) => (
          <Card key={i} className={`${item?.approved_by_store_manager} my-2`}>
            <CardHeader
              avatar={
                <Avatar>
                  <LocalShippingIcon color="info" />
                </Avatar>
              }
              action={
                <>
                  <IconButton onClick={() => handleExpandClick(item)}>
                    <ExpandMore
                      expand={Boolean(expanded).toString()}
                      aria-expanded={Boolean(expanded).toString()}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </IconButton>
                  <Button
                    style={{
                      backgroundColor: "white",
                      cursor: "none",
                      fontWeight: 800,
                      marginRight: "8px",
                    }}
                  >
                    {item?.fuel_check_status === true
                      ? "Completed"
                      : "Requested"}
                  </Button>
                </>
              }
              title={item?.vehicle?.reg_no}
              subheader={item?.vehicle?.vehicle_type?.name}
            />

            {item?._id === selectedRow && (
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography paragraph>
                    <span className="text-orange-700 pr-2">Time of fill:</span>
                    {LocalTime(item?.fuel_issue_time)}
                  </Typography>

                  <Typography paragraph>
                    <span className="text-orange-700 pr-2">
                      Requested Volume:
                    </span>
                    {item?.fuel_vol}
                  </Typography>

                  <Typography paragraph>
                    <span className="text-orange-700 pr-2">Filled volume:</span>
                    {item?.revised_fuel_vol}
                  </Typography>

                  <Typography paragraph>
                    <span className="text-orange-700 pr-2">
                      Hour meter reading:
                    </span>
                    {item?.revised_hour_meter}
                  </Typography>

                  <Typography paragraph>
                    <span className="text-orange-700 pr-2">Driver Name:</span>
                    {item?.vehicle?.driver?.name}
                  </Typography>

                  <Typography paragraph>
                    <span className="text-orange-700 pr-2">
                      Request raised by:
                    </span>
                    {item?.user?.userName}
                  </Typography>
                </CardContent>
                <IconButton onClick={() => setSelectedId(item?._id)}>
                  <Delete />
                </IconButton>

                <Link to={`/store/edit_NDC_log/${item?._id}`}>
                  <Edit />
                </Link>
              </Collapse>
            )}
          </Card>
        ))}
      </div>

      <Popup open={Boolean(selectedId)}>
        <h6 className="text-bold">Are you sure want to delete?</h6>

        <div className="flex gap-2 mt-4">
          <ButtonMUI
            fullWidth
            type="button"
            onClick={() => setSelectedId(false)}
            text="Cancel"
          />

          <ButtonMUI
            fullWidth
            type="button"
            onClick={deleteFuelEntry}
            text="delete"
          />
        </div>
      </Popup>
    </>
  );
};

export default SupervisorDNCCompletedLogs;
