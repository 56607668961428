import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const menu = [
  {
    name: "Admin dashboard",
    link: "/admin/dashboard",
    supervisor: false,
    admin: true,
  },
  {
    name: "Dashboard",
    link: "/supervisor/dashboard",
    supervisor: true,
    admin: true,
  },
  {
    name: "Change password",
    link: "/change_password",
    supervisor: true,
    admin: false,
  },
];

const SupervisorMenu = () => {
  const pathname = useLocation();

  const user = JSON.parse(localStorage.getItem("user"))?.user_type?.type;

  return (
    <div className="w-full">
      <List>
        {menu.map(
          (text, index) =>
            (user === "Supervisor" ||
            user === "Supervisor Hod" ||
            user === "Vendor"
              ? text.supervisor
              : text.admin) && (
              <Link key={index} to={text.link}>
                <ListItem
                  sx={{
                    backgroundColor:
                      pathname.pathname === text.link ? "orange" : "",
                  }}
                  key={index}
                  disablePadding
                >
                  <ListItemButton>
                    <div className="flex gap-1 items-center">
                      {/* <LocalShippingIcon /> */}
                      <ListItemText primary={text.name} />
                    </div>
                  </ListItemButton>
                </ListItem>
              </Link>
            )
        )}
      </List>
    </div>
  );
};

export default SupervisorMenu;
