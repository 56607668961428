import React from "react";
import { Navigate } from "react-router-dom";
import { shallow } from "zustand/shallow";
import Store from "./store/loggerStore";

const ProtectedRoute = ({ children }) => {
  const { user } = Store(
    (state) => ({
      user: state.user,
    }),
    shallow
  );
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};

export default ProtectedRoute;
