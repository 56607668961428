import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import ButtonMUI from "../../../components/Atoms/Button";
import InputMUI from "../../../components/Atoms/InputMUI/InputMUI";
import Loader from "../../../components/common/Loader";
import { GenerateRefNumber } from "../../../components/common/serialNumberGeneration";
import {
  InputDateTimeFormat,
  UTCFormat,
} from "../../../components/common/time";
import { useApi } from "../../../hooks/useApi";
import useSupervisorStore from "../supervisorStoe";

const ReleaseVehicleForRepairing = ({ handleClose, ClosePopups, userId }) => {
  const working_area_id = JSON.parse(
    localStorage.getItem("user")
  )?.working_area;

  const { selectedVehicleData, setDataUpdate } = useSupervisorStore(
    (state) => ({
      selectedVehicleData: state.selectedVehicleData,
      setDataUpdate: state.setDataUpdate,
    }),
    shallow
  );

  const { get, put, post, patch, loading } = useApi();

  const [error, setError] = useState("");
  const [closingTime, setClosingTime] = useState(
    selectedVehicleData?.closing_time
  );

  const handleClosintTimeChange = (value) => {
    if (selectedVehicleData?.closing_time > value) {
      setError("Release time can not be less than previous closing time");
    } else {
      setClosingTime(value);
      setError("");
    }
  };

  useEffect(() => {
    handleClose();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let opening_payload = {
      user: userId,
      working_area: working_area_id,
      vehicle: selectedVehicleData?.vehicle?._id,
      slab_rate: selectedVehicleData?.vehicle?.default_slab,
      opening_hr_meter: selectedVehicleData?.closing_hr_meter,
      opening_time: UTCFormat(data.get("time_of_release")),
      release_request_status: true,
    };

    let fuel_issue_payload = {
      fuel_issue_time: UTCFormat(data.get("time_of_release")),
      fuel_vol: data.get("fuel_vol"),
      temp_driver: data.get("temp_driver"),
      approved_by_store_manager: "pending",
    };
    if (!selectedVehicleData?.vehicle?.diesel_chargable) {
      const serialNumber = await GenerateRefNumber(
        selectedVehicleData?.vehicle?.plant_name?._id,
        "FuelIssue",
        "HSD",
        get,
        put
      );

      fuel_issue_payload.fuel_request_number = serialNumber;
    }

    let closing_payload = {
      closing_hr_meter: selectedVehicleData?.closing_hr_meter,
      closing_time: UTCFormat(data.get("time_of_release")),
    };

    let status_payload = {
      status: "release_request",
    };

    if (!error) {
      try {
        let response;
        response = await post("supervisor-add-entry/create", opening_payload);

        if (selectedVehicleData?.diesel_chargable) {
          if (!response?.error) {
            response = await patch(
              `supervisor-add-entry/update-closing/${selectedVehicleData?.vehicle?._id}`,
              closing_payload
            );

            if (!response?.error) {
              response = await patch(
                `vehicle/update_release_stage/${selectedVehicleData?.vehicle?._id}`,
                status_payload
              );
            }
          }
        } else {
          if (!response?.error) {
            response = await patch(
              `supervisor-add-entry/update/${selectedVehicleData?.vehicle?._id}`,
              fuel_issue_payload
            );

            if (!response?.error) {
              response = await patch(
                `supervisor-add-entry/update-closing/${selectedVehicleData?.vehicle?._id}`,
                closing_payload
              );

              if (!response?.error) {
                response = await patch(
                  `vehicle/update_release_stage/${selectedVehicleData?.vehicle?._id}`,
                  status_payload
                );
              }
            }
          }
        }
        if (!response?.error) {
          ClosePopups();
          setDataUpdate(event.timeStamp);
        }
      } catch (err) {
        setError(err);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Loader open={loading} />
      <div className="grid grid-cols-12 items-center gap-4 pb-4">
        <div className="col-span-12 lg:col-span-6">
          <InputMUI
            name="hour_meter_reading"
            type="number"
            label="Hour Meter reading:"
            value={selectedVehicleData?.closing_hr_meter}
            readOnly
            required
          />
        </div>

        <div className="col-span-12 lg:col-span-6">
          <InputMUI
            name="time_of_release"
            label="Time of Release:"
            type="datetime-local"
            step={60}
            value={InputDateTimeFormat(closingTime)}
            onChange={(e) => handleClosintTimeChange(e.target.value)}
            required
          />
        </div>
      </div>
      {!selectedVehicleData?.vehicle?.diesel_chargable && (
        <div className="grid grid-cols-12 items-center gap-4 my-4">
          <div className="col-span-12">
            <InputMUI
              required
              type="text"
              name="temp_driver"
              label="Driver"
              placeholder="Driver"
            />
          </div>

          <div className="col-span-12 lg:col-span-6">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Fuel issue
              </FormLabel>
              <RadioGroup
                name="fuel_vol"
                value="full tank"
                aria-labelledby="demo-radio-buttons-group-label"
              >
                <FormControlLabel
                  control={<Radio />}
                  value="full tank"
                  label="full tank"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      )}
      <p style={{ color: "red" }}>{error}</p>

      <ButtonMUI fullWidth text="Save" type="submit" />
    </form>
  );
};

export default ReleaseVehicleForRepairing;
