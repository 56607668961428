import { MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ButtonMUI from "../../../components/Atoms/Button";
import InputMUI from "../../../components/Atoms/InputMUI/InputMUI";
import SelectInputMUI from "../../../components/Atoms/Select/SelectInputMUI";
import Loader from "../../../components/common/Loader";
import {
  TimeDurationInDays,
  currentDate,
} from "../../../components/common/time";
import { useApi } from "../../../hooks/useApi";
import DashboardStore from "../store/DashboardStore";

const VehicleListSearchSection = () => {
  const { timeStamp, searchText, setSearchText, setvehicleListFilteredData } =
    DashboardStore((state) => ({
      timeStamp: state.timeStamp,
      searchText: state.searchText,
      setSearchText: state.setSearchText,
      setvehicleListFilteredData: state.setvehicleListFilteredData,
    }));

  const { get, loading, data } = useApi();

  const [status, setStatus] = useState([]);

  const getLogisticDashboardData = async () => {
    await get("logistic-dashboard/list");
  };

  useEffect(() => {
    getLogisticDashboardData();
  }, [timeStamp]);

  useEffect(() => {
    let validityFilteredData = [];

    switch (status) {
      case "validity":
        validityFilteredData = data?.filter(
          (vehicle) =>
            vehicle?.vehicle?.status === "active" &&
            TimeDurationInDays(currentDate, vehicle?.vehicle.vehicle_validity) >
              16
        );
        break;
      case "validity_expired":
        validityFilteredData = data?.filter(
          (vehicle) =>
            vehicle?.vehicle?.status === "active" &&
            TimeDurationInDays(currentDate, vehicle?.vehicle.vehicle_validity) <
              16
        );
        break;
      case "active":
        validityFilteredData = data?.filter(
          (vehicle) => vehicle?.vehicle?.status === "active"
        );
        break;
      case "release_request":
        validityFilteredData = data?.filter(
          (vehicle) => vehicle?.vehicle?.status === "release_request"
        );
        break;
      case "temporary_exit":
        validityFilteredData = data?.filter(
          (vehicle) => vehicle?.vehicle?.status === "temporary_exit"
        );
        break;

      case "permanent_exit":
        validityFilteredData = data?.filter(
          (vehicle) => vehicle?.vehicle?.status === "permanent_exit"
        );
        break;

      default:
        validityFilteredData = data;

        break;
    }

    const filtered =
      validityFilteredData?.length > 0 &&
      validityFilteredData?.filter((vehicle) => {
        return [
          vehicle?.vehicle?.reg_no,
          vehicle?.vehicle?.vendor?.vendor_name,
          vehicle?.vehicle?.vehicle_type?.name,
          vehicle?.vehicle?.opening_km?.toFixed(0),
          vehicle?.vehicle?.entryDate,
          vehicle?.opening_hr_meter?.toFixed(0),
        ].some(
          (s) => s && s?.toLowerCase().includes(searchText?.toLowerCase())
        );
      });
    setvehicleListFilteredData(filtered);
    console.log(filtered);
  }, [
    data?.[0]?.updatedAt,
    data?.[0]?.vehicle?.updatedAt,
    data?.length,
    searchText,
    status,
  ]);

  return (
    <>
      <Loader open={loading} />
      <div className="grid grid-cols-12 items-center gap-2">
        <div className="col-span-12 lg:col-span-4">
          <InputMUI
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Search parameter here"
          />
        </div>
        <div className="col-span-12 lg:col-span-4">
          <SelectInputMUI
            label="Status"
            placeholder="Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value="validity">Validity</MenuItem>
            <MenuItem value="validity_expired">Validity expired</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="release_request">Release</MenuItem>
            <MenuItem value="temporary_exit">Temporary exit</MenuItem>
            <MenuItem value="permanent_exit">Permanent exit</MenuItem>
          </SelectInputMUI>
        </div>

        <div className="col-span-12 lg:col-span-4">
          <Link to="/logistic/addVehicle">
            <ButtonMUI fullWidth text="Add new vehicle" />
          </Link>
        </div>
      </div>

      <div className="flex flex-wrap gap-4 items-center justify-center py-2">
        <div className="flex items-center gap-2">
          <span className="rounded-full bg-pink-400  h-4 w-4"></span>
          <span className="text-pink-400 ">Validity expiring</span>
        </div>

        <div className="flex items-center gap-2">
          <span className="rounded-full bg-[#6c86d2] h-4 w-4"></span>
          <span className="text-[#6c86d2]">Release</span>
        </div>

        <div className="flex items-center gap-2">
          <span className="rounded-full bg-[#ffb88b] h-4 w-4"></span>
          <span className="text-[#ffb88b]">Active</span>
        </div>

        <div className="flex items-center gap-2">
          <span className="rounded-full bg-[#c4c4c4] h-4 w-4"></span>
          <span className="text-[#c4c4c4]">Temporary Exit</span>
        </div>

        <div className="flex items-center gap-2">
          <span className="rounded-full bg-black h-4 w-4"></span>
          <span className="text-black">Permanent Exit</span>
        </div>
      </div>
    </>
  );
};

export default VehicleListSearchSection;
