import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../assets/icon";
import DashboardStore from "../../pages/Dashboard/store/DashboardStore";
import useSupervisorStore from "../../pages/Supervisor/supervisorStoe";
import { shallow } from "zustand/shallow";
import Store from "../../store/loggerStore";

const Bottombar = () => {
  const navigate = useNavigate();

  const { resetSupervisorStore } = useSupervisorStore(
    (state) => ({
      resetSupervisorStore: state.resetData,
    }),
    shallow
  );
  const { resetDashboardStore } = DashboardStore(
    (state) => ({
      resetDashboardStore: state.resetData,
    }),
    shallow
  );

  const { setUser } = Store(
    (state) => ({
      setUser: state.setUser,
    }),
    shallow
  );

  const [isOpen, setIsOpen] = useState(false);

  const Logoutfunc = () => {
    localStorage.clear();
    setUser(null);
    resetSupervisorStore();
    resetDashboardStore();
    navigate("/login");
  };

  return (
    <div className="w-full h-fit p-2 lg:hidden bg-slate-200 flex justify-between items-center sticky bottom-0">
      <Tooltip title="home">{Icon("oc_logo")}</Tooltip>

      <Tooltip title="Logout" onClick={() => setIsOpen(true)}>
        {Icon("logout")}
      </Tooltip>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do You want to Logout!!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>No</Button>
          <Button onClick={Logoutfunc} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Bottombar;
