import React, { useEffect } from "react";
import { shallow } from "zustand/shallow";
import ButtonMUI from "../../../components/Atoms/Button";
import InputMUI from "../../../components/Atoms/InputMUI/InputMUI";
import Loader from "../../../components/common/Loader";
import {
  InputDateTimeFormat,
  UTCFormat,
} from "../../../components/common/time";
import { useApi } from "../../../hooks/useApi";
import useForm from "../../../hooks/useForm";
import useSupervisorStore from "../supervisorStoe";

const OpenTimeComponent = ({ handleClose, userId, ClosePopups }) => {
  const working_area_id = JSON.parse(
    localStorage.getItem("user")
  )?.working_area;

  const { selectedVehicleData, setDataUpdate } = useSupervisorStore(
    (state) => ({
      selectedVehicleData: state.selectedVehicleData,
      setDataUpdate: state.setDataUpdate,
    }),
    shallow
  );

  const initialState = {
    opening_hr_meter: selectedVehicleData?.closing_hr_meter,
    opening_time: selectedVehicleData?.closing_time,
  };

  const { post, loading } = useApi();
  const { values, handleChange } = useForm(initialState);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let payload = {
      user: userId,
      vehicle: selectedVehicleData?.vehicle?._id,
      opening_hr_meter: values?.opening_hr_meter,
      opening_time: UTCFormat(values?.opening_time),
      working_area: working_area_id,
      slab_rate: selectedVehicleData?.vehicle?.default_slab,
    };

    const response = await post("supervisor-add-entry/create", payload);
    if (!response?.error) {
      ClosePopups();
      setDataUpdate(new Date().valueOf());
    }
  };

  useEffect(() => {
    handleClose();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Loader open={loading} />
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-6">
          <InputMUI
            required
            label="Opening Hour Meter"
            type="number"
            placeholder="Opening hours meters"
            name="opening_hr_meter"
            min={selectedVehicleData?.closing_hr_meter}
            value={values.opening_hr_meter}
            onChange={handleChange}
            disabled={!selectedVehicleData?.closing_hr_meter}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InputMUI
            required
            label="Opening Hour Time"
            name="opening_time"
            type="datetime-local"
            step={60}
            placeholder="Opening time"
            value={InputDateTimeFormat(values.opening_time)}
            onChange={handleChange}
            min={InputDateTimeFormat(selectedVehicleData?.closing_time)}
            max={InputDateTimeFormat(new Date())}
            disabled={!selectedVehicleData?.closing_time}
          />
        </div>
        <ButtonMUI
          className="col-span-12"
          disabled={
            !selectedVehicleData?.closing_time ||
            !selectedVehicleData?.closing_hr_meter
          }
          fullWidth
          text="save"
          type="submit"
        />
      </div>
    </form>
  );
};

export default OpenTimeComponent;
