import React from "react";
import { useParams } from "react-router-dom";
import GoBack from "../common/GoBack";
import CSVUploader from "./CSVUploader";
import DownloadCSVTemplate from "./SampleCSVTemplate";

const BulkUpload = () => {
  const { id } = useParams();
  return (
    <div className="flex flex-col gap-4">
      <GoBack>
        Bulk Upload:-<span className="capitalize pl-2">{id}</span>
      </GoBack>

      <div className="flex gap-4 items-center">
        Download Sample Template:
        <DownloadCSVTemplate />
      </div>

      <div className="flex gap-4">
        Upload File:
        <CSVUploader />
      </div>
    </div>
  );
};

export default BulkUpload;
