import { shallow } from "zustand/shallow";
import AccountantDashboard from "./pages/AccountantDash/AccountantDashboard";
import AdminDashboardPage from "./pages/AdminDashboard/AdminDashboardPage";
import VehicleList from "./pages/Dashboard/Vehicle/VehicleList";
import ManagerDashboard from "./pages/Manager/ManagerDashboard";
import SupervisorDNCRequestLogs from "./pages/StoreDashboard/SupervisorDNCRequestLogs";
import SupervisorDashboard from "./pages/Supervisor/SupervisorDashboard/SupervisorDashboard";
import Store from "./store/loggerStore";

const HomePath = () => {
  const { user } = Store(
    (state) => ({
      user: state.user?.user_type?.type,
    }),
    shallow
  );

  switch (user) {
    case "Supervisor":
      return <SupervisorDashboard />;

    case "Supervisor Hod":
      return <SupervisorDashboard />;

    case "Vendor":
      return <SupervisorDashboard />;

    case "Logistic":
      return <VehicleList />;

    case "Store Manager":
      return <ManagerDashboard />;

    case "Store":
      return <SupervisorDNCRequestLogs />;

    case "Store Hod":
      return <SupervisorDNCRequestLogs />;

    case "Accountant":
      return <AccountantDashboard />;

    case "Admin":
      return <AdminDashboardPage />;

    default:
      break;
  }
};

export default HomePath;
