import moment from "moment";
import "moment/min/locales.min";

export const currentTime = moment();

export const currentDate = moment.utc();

const StartOfTheDay = currentDate.startOf("day").toString();

const EndOfTheDay = currentDate.endOf("day").toString();

export const startOfTheMonth = currentTime.subtract(1, "month");

// Subtract 1 day to get yesterday's date
const yesterdayDate = currentDate.subtract(1, "day");

// Set the start time to 00:00:00
const startTimeOfYesterday = yesterdayDate.startOf("day").toString();

// Set the end time to 23:59:59
const endTimeOfYesterday = yesterdayDate.endOf("day").toString();

export { StartOfTheDay, EndOfTheDay, startTimeOfYesterday, endTimeOfYesterday };

export const Before5Mins = currentTime.subtract(5, "minutes").valueOf();

export const ValueOf = (time) => moment(time).valueOf();

export const LocalTime = (time) =>
  time ? moment(time).format("DD-MM-YYYY LT") : "NA";

export const Date = (time) => (time ? moment(time).format("DD-MM-YYYY") : "NA");

export const FromNow = (time) => moment(time).fromNow();

export const Duration = (startTime, endTime) =>
  endTime
    ? moment.duration(moment(endTime)?.diff(moment(startTime))).asHours()
    : 0;

export const InputFormat = (time) => moment(time).format("DD-MM-YYYYTHH:mm");

export const InputDateTimeFormat = (time) =>
  time ? moment(time).format("YYYY-MM-DDTHH:mm") : "";

export const InputDate = (time) =>
  time ? moment(time).format("YYYY-MM-DD") : "";

// last month start and end date

// Calculate the first day of the current month
export const firstDayOfLastMonth = currentDate
  .clone()
  .subtract(1, "month")
  .startOf("month");

// Calculate the last day of the last month
export const lastDayOfLastMonth = currentDate.clone().startOf("month");

// time format

// Current date and time

// Format the current time as 'HH:mm:ss'
export const formattedTime = (time) => moment(time).format("HH:mm");

export const UTCFormat = (time) => (time ? moment(time).utc() : "");

export const AlphaNumFormat = (time) =>
  moment(time).format("dddd, MMMM D, YYYY");

// time diff
export const TimeDuration = (start, end) => {
  const startTime = moment(start, "HH:mm");
  const endTime = moment(end, "HH:mm");

  const duration = moment.duration(endTime.diff(startTime));

  const hours = Math.floor(duration.asHours());

  return hours === 0 ? 24 : hours;
};

export const TimeDurationInDays = (start, end) => {
  const startDate = moment(start);
  const endDate = moment(end);

  const duration = endDate.diff(startDate, "days");

  return duration;
};

export const fromDate = (days) => moment().subtract(days, "days").toDate();
