import crane from "./crane.png";
import dozer from "./dozer.png";
import loader from "./loader.png";
import logout from "./logout.png";
import oc_logo from "./oc_logo.svg";
import person from "./person.png";
import poclain from "./poclain.png";
import report from "./report.png";
import truck from "./truck.png";

const icon = {
  poclain: poclain,
  truck: truck,
  loader: loader,
  dozer: dozer,
  crane: crane,
  person: person,
  truck: report,
  logout: logout,
  oc_logo: oc_logo,
};

export const Icon = (type) => (
  <img
    src={Object.keys(icon).includes(type) ? icon[type] : icon.truck}
    alt="icon"
  />
);
