import { MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import InputMUI from "../../../../components/Atoms/InputMUI/InputMUI";
import SelectInputMUI from "../../../../components/Atoms/Select/SelectInputMUI";
import Loader from "../../../../components/common/Loader";
import { useApi } from "../../../../hooks/useApi";
import useSupervisorStore from "../../supervisorStoe";

const SearchSection = () => {
  const {
    setSearchText,
    searchText,
    setFilteredData,
    filteredData,
    dataChange,
  } = useSupervisorStore(
    (state) => ({
      setSearchText: state.setSearchText,
      searchText: state.searchText,
      setFilteredData: state.setFilteredData,
      filteredData: state.filteredData[0],
      dataChange: state.dataChange,
    }),
    shallow
  );

  let { accessToken } = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : "";

  const user = JSON.parse(localStorage.getItem("user"))?.user_type?.type;

  const { get, loading, data } = useApi();

  const [status, setStatus] = useState([]);

  const getLogisticDashboardData = async () => {
    user === "Admin"
      ? await get("admin-dashboard/supervisor_entries")
      : await get("supervisor-dashboard/list", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  };

  useEffect(() => {
    getLogisticDashboardData();
  }, [dataChange]);

  useEffect(() => {
    const filtered =
      data?.length > 0 &&
      data
        ?.filter((vehicle) =>
          !status.length || status.length
            ? !status?.includes("inactive") && status?.includes("active")
              ? !vehicle?.closing_time && !vehicle?.closing_hr_meter
              : status?.includes("inactive") && !status?.includes("active")
              ? vehicle?.closing_time && vehicle?.closing_hr_meter
              : true
            : true
        )
        ?.filter((vehicle) => {
          return [
            vehicle?.vehicle?.reg_no,
            vehicle?.vehicle?.vendor?.vendor_name,
            vehicle?.vehicle?.vehicle_type?.name,
            vehicle?.vehicle?.opening_km?.toFixed(0),
            vehicle?.vehicle?.entryDate,
            vehicle?.opening_time,
            vehicle?.closing_time,
            vehicle?.opening_hr_meter?.toFixed(0),
            vehicle?.closing_hr_meter?.toFixed(0),
          ].some(
            (s) => s && s?.toLowerCase().includes(searchText?.toLowerCase())
          );
        });
    setFilteredData(filtered);
  }, [
    data?.[0]?.updatedAt,
    data?.[0]?.vehicle?.updatedAt,
    data?.length,
    searchText,
    status,
  ]);
  return (
    <div className="grid grid-cols-12 items-center gap-2 py-2">
      <Loader open={loading} />
      {(user === "Supervisor" || user === "Supervisor Hod") && (
        <h6 className="col-span-12 lg:col-span-4 capitalize my-2">
          <span className="font-bold text-orange-600 pr-2 uppercase">
            Working Area:
          </span>
          {filteredData?.working_area?.name}
        </h6>
      )}
      <div className="col-span-12 lg:col-span-4">
        <InputMUI
          label="Search here"
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search here..."
        />
      </div>

      <div className="col-span-12 lg:col-span-4">
        <SelectInputMUI
          label="Status"
          placeholder="Status"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          multiple
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="inactive">Inactive</MenuItem>
        </SelectInputMUI>
      </div>
    </div>
  );
};

export default SearchSection;
