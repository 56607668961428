import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const menu = [
  {
    name: "Admin dashboard",
    link: "/admin/dashboard",
    store: false,
    storeHod: false,
    admin: true,
  },
  {
    name: "Disel Price",
    link: "/disel-price",
    store: false,
    storeHod: true,
    admin: true,
  },
  {
    name: "Supervisor Diesel non chargeable request log",
    link: "/store/requested-logs",
    store: true,
    storeHod: true,
    admin: true,
  },
  {
    name: "Supervisor Diesel non chargeable completed log",
    link: "/store/completed-logs",
    store: true,
    storeHod: true,
    admin: true,
  },
  {
    name: "Entry/Exit/Diesel chargeable log",
    link: "/store/requested-vehicle",
    store: true,
    storeHod: true,
    admin: true,
  },
  {
    name: "Entry/Exit/Diesel chargeable completed log",
    link: "/store/completed-vehicle",
    store: true,
    storeHod: true,
    admin: true,
  },
  {
    name: "Change password",
    link: "/change_password",
    store: true,
    storeHod: true,
    admin: false,
  },
];

const StoreDashboardMenu = () => {
  const pathname = useLocation();

  const user = JSON.parse(localStorage.getItem("user"))?.user_type?.type;

  return (
    <List>
      {menu.map(
        (text, index) =>
          (user === "Store"
            ? text.store
            : user === "Store Hod"
            ? text.storeHod
            : text.admin) && (
            <Link key={index} to={text.link}>
              <ListItem
                sx={{
                  backgroundColor:
                    pathname.pathname === text.link ? "orange" : "",
                }}
                key={index}
                disablePadding
              >
                <ListItemButton>
                  <div className="flex gap-1 items-center">
                    {/* <LocalShippingIcon /> */}
                    <ListItemText primary={text.name} />
                  </div>
                </ListItemButton>
              </ListItem>
            </Link>
          )
      )}
    </List>
  );
};

export default StoreDashboardMenu;
