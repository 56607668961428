import { MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import { shallow } from "zustand/shallow";
import ButtonMUI from "../../../components/Atoms/Button";
import SelectInputMUI from "../../../components/Atoms/Select/SelectInputMUI";
import Loader from "../../../components/common/Loader";
import { useApi } from "../../../hooks/useApi";
import useForm from "../../../hooks/useForm";
import useSupervisorStore from "../supervisorStoe";

const SlabRateChange = ({ handleClose, ClosePopups }) => {
  const { selectedVehicleData, setDataUpdate } = useSupervisorStore(
    (state) => ({
      selectedVehicleData: state.selectedVehicleData,
      setDataUpdate: state.setDataUpdate,
    }),
    shallow
  );

  const { patch, loading } = useApi();
  const { values, handleChange } = useForm();

  const handleSubmitWorkingTime = async (event) => {
    event.preventDefault();

    let payload = {
      slab_rate: values?.working_time,
    };

    const response = await patch(
      `vehicle/update-working_time/${selectedVehicleData?.vehicle?._id}`,
      payload
    );

    if (!response?.error) {
      setDataUpdate(new Date().valueOf());
      ClosePopups();
    }
  };

  useEffect(() => {
    handleClose();
  }, []);

  return (
    <form onSubmit={handleSubmitWorkingTime} className="flex flex-col gap-4">
      <Loader open={loading} />

      <SelectInputMUI
        label="New Working time:"
        name="working_time"
        style={{ width: "100%" }}
        required
        defaultValue={selectedVehicleData?.vehicle?.default_slab}
        value={values?.working_time}
        onChange={handleChange}
      >
        {selectedVehicleData?.vehicle?.slab.map((x, i) => (
          <MenuItem key={i} value={x?.slab_type}>
            {x?.no_of_hours}
          </MenuItem>
        ))}
      </SelectInputMUI>

      <ButtonMUI fullWidth text="Save" type="submit" />
    </form>
  );
};

export default SlabRateChange;
