import { ExpandMore } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Avatar,
  Card,
  CardHeader,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";
import { Icon } from "../../assets/icon";
import InputMUI from "../../components/Atoms/InputMUI/InputMUI";
import Loader from "../../components/common/Loader";
import {
  InputDate,
  firstDayOfLastMonth,
  lastDayOfLastMonth,
} from "../../components/common/time";
import { useApi } from "../../hooks/useApi";
import useAccountantStore from "./store/accountantStore";

const AccountantDashboard = (props) => {
  const navigate = useNavigate();

  const {
    UpdateDates,
    searchText,
    setLoading,
    setSearchText,
    filteredData,
    setFilteredData,
  } = useAccountantStore(
    (state) => ({
      UpdateDates: state.UpdateDates,
      searchText: state.searchText,
      setLoading: state.setLoading,
      setSearchText: state.setSearchText,
      filteredData: state.filteredData,
      setFilteredData: state.setFilteredData,
    }),
    shallow
  );

  const { get, loading, data } = useApi();

  const [expanded, setExpanded] = useState(false);
  const [selectedRow, SetselectedRow] = useState();

  const handleExpandClick = (item) => {
    setExpanded(!expanded);
    SetselectedRow(item._id);
  };

  const getAllVehicle = async () => {
    setLoading(true);
    await get("accoutant-dashboard/accountant_all_vehicle_list");
  };

  useEffect(() => {
    getAllVehicle();
  }, []);

  useEffect(() => {
    const filtered =
      data?.length > 0 &&
      data?.filter((vehicle) => {
        return [
          vehicle?.reg_no,
          vehicle?.vendor?.vendor_name,
          vehicle?.vehicle_type?.name,
          vehicle?.allowed_mileage?.toFixed(0),
        ].some((s) => s && s?.toLowerCase().includes(searchText.toLowerCase()));
      });
    setFilteredData(filtered);
  }, [data?.length, searchText]);

  const columns = [
    {
      field: "reg_no",
      headerName: "Vehicle",
      width: 200,
    },
    {
      field: "vendor_name",
      headerName: "Vendor",
      width: 200,
      renderCell: (e) => (
        <>
          <div style={{ cursor: "pointer" }} className="tableBtn">
            {e.row?.vendor?.vendor_name}
          </div>
        </>
      ),
    },

    {
      field: "Vehicle type",
      headerName: "Vehicle type",
      width: 200,
      renderCell: (e) => e.row?.vehicle_type?.name,
    },

    {
      field: "allowed_mileage",
      headerName: "Mileage(km/ltr)",
      width: 200,
    },
  ];

  return (
    <>
      <Loader open={loading} />
      <InputMUI
        type="text"
        name="reg_no"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Search here..."
        autoComplete="off"
      />
      <h5 className="font-bold my-2">Select vehicle to generate report</h5>

      <div className="hidden lg:block h-[70vh]">
        <DataGrid
          onRowClick={(e) => {
            UpdateDates({
              startDate:
                InputDate(firstDayOfLastMonth) +
                "T" +
                moment(e.row?.operation_start_time, "HH:mm").format("HH:mm"),
              endDate:
                InputDate(lastDayOfLastMonth) +
                "T" +
                moment(e.row?.operation_end_time, "HH:mm")
                  .subtract(1, "minutes")
                  .format("HH:mm"),
            });
            navigate(`/report-details/${e.row._id}`);
          }}
          getRowId={(row) => row._id}
          rows={filteredData?.length > 0 ? filteredData : []}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </div>
      <div className="lg:hidden block h-[90vh]">
        {filteredData?.length > 0 &&
          filteredData?.map((item, i) => (
            <Card key={i} className="my-2">
              <CardHeader
                avatar={<Avatar>{Icon(item?.vehicle_type?._id)}</Avatar>}
                action={
                  <IconButton onClick={() => handleExpandClick(item)}>
                    <ExpandMore
                      expand={Boolean(expanded).toString()}
                      aria-expanded={Boolean(expanded).toString()}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </IconButton>
                }
                title={item?.reg_no}
                subheader={item?.vehicle_type?.name}
              />

              {item?._id === selectedRow && (
                <Collapse
                  sx={{ cursor: "pointer" }}
                  onClick={(e) => {
                    UpdateDates({
                      startDate:
                        InputDate(firstDayOfLastMonth) +
                        "T" +
                        moment(e.row?.operation_start_time).format("HH:mm"),
                      endDate:
                        InputDate(lastDayOfLastMonth) +
                        "T" +
                        moment(item?.operation_end_time, "HH:mm")
                          .subtract(1, "minutes")
                          .format("HH:mm"),
                    });
                    navigate(`/report-details/${item?._id}`);
                  }}
                  in={expanded}
                  timeout="auto"
                  unmountOnExit
                >
                  <div className="flex flex-wrap items-center justify-between px-2 gap-x-4">
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2"> Vendor:</span>
                      {item?.vendor?.vendor_name}
                    </Typography>
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">
                        Mileage(km/ltr):
                      </span>

                      {item?.allowed_mileage}
                    </Typography>
                  </div>
                </Collapse>
              )}
            </Card>
          ))}
      </div>
    </>
  );
};

export default AccountantDashboard;
