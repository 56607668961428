import React from "react";
import ButtonMUI from "../Atoms/Button";
import { Link, useParams } from "react-router-dom";

const DownloadCSVTemplate = () => {
  const { id } = useParams();
  return (
    <Link to={`/files/${id}.csv`} target="_blank" download>
      <ButtonMUI type="button" text="Download" />
    </Link>
  );
};

export default DownloadCSVTemplate;
