import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../../hooks/useApi";
import Loader from "../../components/common/Loader";

const AdminDashboardPage = () => {
  const { get, loading, data } = useApi();

  const getAdmindata = async () => {
    await get("admin-dashboard/list");
  };

  useEffect(() => {
    getAdmindata();
  }, []);

  const Item = ({ title, count, link }) => {
    return (
      <div className="col-span-6 p-8 lg:col-span-4 flex flex-col items-center justify-center shadow-lg cursor-pointer">
        <Link to={link} className="h-full w-full">
          <h4 className="font-[600] ">{title}</h4>
          <span className="text-orange-600 text-[20px]">{count}</span>
        </Link>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-12 items-center gap-4">
      <Loader open={loading} />
      <Item
        title="Total Vendor"
        count={data?.totalVendor}
        link="/logistic/vendor-list"
      />

      <Item
        title="Total Vehicle"
        count={data?.totalVehicle}
        link="/logistic/vehicle-list"
      />

      <Item
        title="Active Vehicle"
        count={data?.totalActiveVehicle}
        link="/logistic/vehicle-list"
      />

      <Item
        title="Total Driver"
        count={data?.totalDriver}
        link="/admin/driver"
      />

      <Item
        title="Total Entry"
        count={data?.totalEntry}
        link="/supervisor/dashboard"
      />

      <Item
        title="Fuel Issue Times"
        count={data?.totalFuelIssue}
        link="/store/requested-logs"
      />

      <Item title="Total users" count={data?.totalUsers} link="/admin/user" />
    </div>
  );
};

export default AdminDashboardPage;
