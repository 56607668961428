import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../hooks/useApi";
import ButtonMUI from "../Atoms/Button";
import Loader from "../common/Loader";

const CSVUploader = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { post, loading } = useApi();

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const removeSelectedFile = () => {
    setSelectedFile(null);
    const fileInput = document.getElementById("fileInput");
    fileInput.value = "";
  };

  const handleUpload = async () => {
    if (selectedFile && id) {
      const payload = { file: selectedFile };
      try {
        const response = await post(`executeimport`, payload, {
          headers: { "Content-Type": "multipart/form-data" },
          maxBodyLength: Infinity,
        });

        if (!response?.error) {
          removeSelectedFile();
          navigate(-1, { replace: true });
        }
      } catch (err) {
        alert(err);
      }
    }
  };

  return (
    <div className="flex flex-col">
      <Loader open={loading} />

      <input
        type="file"
        id="fileInput"
        accept=".csv"
        onChange={handleFileChange}
      />

      <div className="flex gap-3 mt-4">
        <ButtonMUI
          disabled={!selectedFile}
          className="mr-4"
          onClick={handleUpload}
          text="upload"
        />

        <ButtonMUI
          disabled={!selectedFile}
          onClick={removeSelectedFile}
          text="remove"
        />
      </div>
    </div>
  );
};

export default CSVUploader;
