import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";
import useSupervisorStore from "../../supervisorStoe";

const MoreOptionContainer = ({
  OpenModalHandler,
  anchorEl,
  open,
  handleClose,
}) => {
  const navigate = useNavigate();

  const { selectedVehicleData } = useSupervisorStore(
    (state) => ({
      selectedVehicleData: state.selectedVehicleData,
    }),
    shallow
  );

  const user = JSON.parse(localStorage.getItem("user"))?.user_type?.type;

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      {user !== "Vendor" &&
      selectedVehicleData &&
      selectedVehicleData?.closing_time &&
      selectedVehicleData?.closing_hr_meter
        ? [
            <MenuItem
              key="open-time"
              onClick={() => OpenModalHandler("open-time")}
            >
              Add Opening Time
            </MenuItem>,
            <MenuItem
              key="hour-meter-change"
              onClick={() => OpenModalHandler("hour_meter_change")}
            >
              Hour meter Change
            </MenuItem>,
            <MenuItem
              key="working-area"
              onClick={() => OpenModalHandler("working-area")}
            >
              Change Working Area
            </MenuItem>,
            <MenuItem
              key="slab-rate"
              onClick={() => OpenModalHandler("slab_rate")}
            >
              Change working time
            </MenuItem>,
            <MenuItem
              key="release-vehicle"
              onClick={() => OpenModalHandler("release_vehicle_for_repairing")}
            >
              Release Vehicle for repairing
            </MenuItem>,
          ]
        : [
            <MenuItem
              key="close-time"
              onClick={() => OpenModalHandler("close-time")}
            >
              Add Closing Time
            </MenuItem>,
            !selectedVehicleData.breakdown_closing_time ? (
              <MenuItem
                key="break-time"
                onClick={() => OpenModalHandler("break-time")}
              >
                Add Break Down
              </MenuItem>
            ) : (
              <MenuItem
                key="again-breakdown"
                onClick={() => OpenModalHandler("AgianBreakdown")}
              >
                Another Break Down
              </MenuItem>
            ),
            selectedVehicleData?.fuel_vol ? (
              <MenuItem
                key="reissue-fuel"
                onClick={() => OpenModalHandler("ReIssue-Fuel")}
              >
                Re-Issue Fuel
              </MenuItem>
            ) : (
              <MenuItem
                key="fuel-issue"
                onClick={() => OpenModalHandler("fule-issue")}
              >
                Add Fuel Issue
              </MenuItem>
            ),
          ]}
      <MenuItem
        onClick={() =>
          navigate(
            `/supervisor/vehicle-logs/${selectedVehicleData?.vehicle?._id}`
          )
        }
      >
        Vehicle log
      </MenuItem>
      {(user === "Supervisor Hod" || user === "Vendor") && (
        <MenuItem
          onClick={() =>
            navigate(`/old-report/${selectedVehicleData?.vehicle?._id}`)
          }
        >
          Reports
        </MenuItem>
      )}
    </Menu>
  );
};

export default MoreOptionContainer;
