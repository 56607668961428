import React from "react";
import { useLocation } from "react-router-dom";
import { shallow } from "zustand/shallow";
import AccountantMenu from "../pages/AccountantDash/components/AccountantMenu";
import AdminMenu from "../pages/AdminDashboard/components/AdminDashboardMenu";
import LogisticMenu from "../pages/Dashboard/components/LogisticMenu";
import StoreMenu from "../pages/Manager/components/StoreMenu";
import StoreDashboardMenu from "../pages/StoreDashboard/components/StoreDashboardMenu";
import SupervisorMenu from "../pages/Supervisor/SupervisorDashboard/components/SupervisorMenu";
import Store from "../store/loggerStore";

const SideMenu = () => {
  const pathName = useLocation();

  const { user } = Store(
    (state) => ({
      user: state.user?.user_type?.type,
    }),
    shallow
  );

  switch (user) {
    case "Supervisor":
      return <SupervisorMenu />;

    case "Supervisor Hod":
      return <SupervisorMenu />;

    case "Vendor":
      return <SupervisorMenu />;

    case "Logistic":
      return <LogisticMenu />;

    case "Store Manager":
      return <StoreMenu />;

    case "Store":
      return <StoreDashboardMenu />;

    case "Store Hod":
      return <StoreDashboardMenu />;

    case "Accountant":
      return <AccountantMenu />;

    case "Accounts Hod":
      return <AccountantMenu />;

    case "Admin":
      if (pathName.pathname.includes("store/")) {
        return <StoreDashboardMenu />;
      } else if (pathName.pathname.includes("supervisor/")) {
        return <SupervisorMenu />;
      } else if (pathName.pathname.includes("logistic/")) {
        return <LogisticMenu />;
      } else {
        return <AdminMenu />;
      }

    default:
      break;
  }
};

export default SideMenu;
