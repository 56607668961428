import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { shallow } from "zustand/shallow";
import ButtonMUI from "../../../components/Atoms/Button";
import InputMUI from "../../../components/Atoms/InputMUI/InputMUI";
import SelectInputMUI from "../../../components/Atoms/Select/SelectInputMUI";
import Popup from "../../../components/MUI/Popup";
import Loader from "../../../components/common/Loader";
import {
  InputDateTimeFormat,
  UTCFormat,
} from "../../../components/common/time";
import { useApi } from "../../../hooks/useApi";
import DashboardStore from "../store/DashboardStore";

const ExitTime = ({ modalOpen, setModalOpen }) => {
  const { selectVehicleData, setTimeStamp } = DashboardStore(
    (state) => ({
      selectVehicleData: state.selectVehicleData,
      setTimeStamp: state.setTimeStamp,
    }),
    shallow
  );
  const { get, patch, loading } = useApi();

  async function getRelevantSeriesNumber(seriesType, plantId, numbers) {
    const payload = {
      type: seriesType.type,
      deptHeader: seriesType.deptHeader,
      plant_id: plantId,
    };
    const serialNumberRes = await get(
      `reqserialnumber?type=${payload?.type}&deptHeader=${payload?.deptHeader}&plantId=${payload?.plant_id}`
    );

    if (!serialNumberRes?.error) {
      const number = serialNumberRes?.serialNumber;
      numbers[seriesType.type] = number;
    }
  }
  const userId = JSON.parse(localStorage.getItem("user"))?._id;

  const handleGatePassSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);

    const payload = {
      user: userId,
      timeOfAction: new Date(),
      release_stage: "gate_pass_issued",
      status: "temporary_exit",
      closing_hour_meter: data.get("closing_hour_meter"),
      closing_hour_date: UTCFormat(data.get("closing_hour_date")),
      comment: data.get("comment"),
    };

    const seriesTypes = [
      {
        type: "GatePass",
        deptHeader: "LOG/GP",
      },
    ];

    const numbers = {};
    const plantId = selectVehicleData?.vehicle?.plant_name?._id;
    for (const seriesType of seriesTypes) {
      await getRelevantSeriesNumber(seriesType, plantId, numbers);
    }

    if (numbers["GatePass"]) {
      payload.exit_reference_no = numbers["GatePass"];
      payload.exit_reference_date = moment.utc();
    }

    const response = await patch(
      "vehicle/update_release_stage/" + selectVehicleData?.vehicle?._id,
      payload
    );

    if (!response?.error) {
      setTimeStamp(new Date().valueOf());
      setModalOpen(false);
    }
  };

  const onVehicleExitTime = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const userData = {
      user: userId,
      timeOfAction: new Date(),
      closing_hour_meter: data.get("closing_hour_meter"),
      closing_hour_date: UTCFormat(data.get("closing_hour_date")),
      fuel_vol: data.get("fuel_vol"),
      temp_driver: data.get("temp_driver") || null,
      status: data.get("status"),
      comment: data.get("comment"),
      replacement_chain: [],
    };

    const isChargeable = selectVehicleData?.vehicle?.diesel_chargable;
    const plantId = selectVehicleData?.vehicle?.plant_name?._id;
    const seriesTypes = [
      {
        type: "DieselExit",
        deptHeader: "LOG/EXT",
      },
    ];

    if (!isChargeable) {
      seriesTypes.unshift({
        type: "FuelIssue",
        deptHeader: "HSD",
      });
    }

    const numbers = {};
    for (const seriesType of seriesTypes) {
      await getRelevantSeriesNumber(seriesType, plantId, numbers);
    }

    if (numbers["DieselExit"]) {
      userData.exit_reference_no = numbers["DieselExit"];
      userData.exit_reference_date = moment.utc();
    }

    const vehicleId = selectVehicleData?.vehicle?._id;

    let response;
    response = await patch("vehicle/exit-time/" + vehicleId, userData);

    if (!response?.error) {
      if (numbers["FuelIssue"]) {
        const fuel_payload = { fuel_request_number: numbers["FuelIssue"] };
        response = patch(
          `supervisor-add-entry/log_update/${vehicleId}`,
          fuel_payload
        );
      }
    }

    if (!response?.error) {
      setTimeStamp(new Date().valueOf());
      setModalOpen(false);
    }
  };

  return (
    <>
      <Loader open={loading} />
      <Popup open={modalOpen} onClose={() => setModalOpen(false)}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {selectVehicleData?.vehicle?.status !== "release_request"
            ? "Exit vehicle"
            : "Issue Gate pass"}{" "}
          <span style={{ color: "orange" }}>
            {selectVehicleData?.vehicle?.reg_no}
          </span>
        </Typography>

        <form
          onSubmit={(e) =>
            selectVehicleData?.vehicle?.status !== "release_request"
              ? onVehicleExitTime(e)
              : handleGatePassSubmit(e)
          }
        >
          <div className="grid grid-cols-12 gap-4 pt-4">
            <div className="col-span-12">
              <InputMUI
                readOnly
                required
                defaultValue={selectVehicleData?.closing_hr_meter}
                onKeyPress={(e) => {
                  if (!/[0-9 .]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                type="tel"
                name="closing_hour_meter"
                label="Closing hours meter"
              />
            </div>
            <div className="col-span-12">
              <InputMUI
                required
                defaultValue={InputDateTimeFormat(
                  selectVehicleData?.closing_time
                )}
                min={InputDateTimeFormat(selectVehicleData?.closing_time)}
                max={InputDateTimeFormat(moment())}
                type="datetime-local"
                step={60}
                name="closing_hour_date"
                label="Closing hr date"
                readOnly
              />
            </div>

            {selectVehicleData?.vehicle?.status !== "release_request" && (
              <div className="col-span-12">
                <SelectInputMUI
                  required
                  label="Status"
                  placeholder="Status"
                  name="status"
                  value="permanent_exit"
                  readOnly
                >
                  <MenuItem value="permanent_exit">Permanent exit</MenuItem>
                </SelectInputMUI>
              </div>
            )}

            {!selectVehicleData?.vehicle?.diesel_chargable &&
              selectVehicleData?.vehicle?.status !== "release_request" && (
                <>
                  <div className="col-span-12">
                    <InputMUI
                      required
                      type="text"
                      name="temp_driver"
                      label="Driver"
                      placeholder="Driver"
                    />
                  </div>
                  <div className="col-span-12">
                    <FormControl>
                      <FormLabel id="fuel_vol">Fuel issue:</FormLabel>
                      <RadioGroup name="fuel_vol" aria-labelledby="fuel_vol">
                        <FormControlLabel
                          label="Full tank"
                          value="full tank"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </>
              )}

            <div className="col-span-12">
              <InputMUI
                name="comment"
                label="Add comment"
                placeholder="Add comment"
                multiline
                rows={3}
              />
            </div>

            <ButtonMUI fullWidth type="submit" text="Save" />
          </div>
        </form>
      </Popup>
    </>
  );
};

export default ExitTime;
