import { ArrowBack } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";

const GoBack = ({ children }) => {
  const navigate = useNavigate();

  return (
    <h4 className="flex gap-4 items-center">
      <span
        onClick={() => navigate(-1, { replace: true })}
        className="cursor-pointer"
      >
        <ArrowBack />
      </span>
      {children}
    </h4>
  );
};

export default GoBack;
